@import '~/client/src/shared/theme.module';

$avatar-container-size: 56px;
$cross-badge-holder-size: 20px;
$cross-icon-size: 15px;
$member-holder-width: 70px;

.selected-members-list-container {
  .avatar-container {
    width: $avatar-container-size;
    height: $avatar-container-size;

    .avatar-status {
      width: 15px;
      height: 15px;
      border-width: 2px;
    }

    .initials {
      font-size: calc($avatar-container-size / 2);
    }
  }

  .member-holder {
    max-width: $member-holder-width;
    min-width: $member-holder-width;
  }

  .cross-badge {
    svg {
      width: $cross-icon-size;
      height: $cross-icon-size;
    }

    position: absolute;
    width: $cross-badge-holder-size;
    height: $cross-badge-holder-size;
    background: get_color('primary', 0, 0.6);
    color: get_color('neutral', 100);
    top: 2%;
    right: 2%;
    border-radius: 50%;
  }
}
