@import '~/client/src/shared/theme.module';

.delivery-tree {
  height: 100%;
  .ReactVirtualized__Grid__innerScrollContainer {
    .delivery-item {
      background: get_color('neutral', 100);
    }

    .delivery-item::-webkit-scrollbar {
      height: 0;
    }

    .delivery-item-main-col {
      background: get_color('neutral', 100);
    }

    .delivery-item-data-col {
      background: get_color('neutral', 100);
    }
  }
}
