@import '~/client/src/shared/theme.module';

$border-radius: 4px;
$modal-top-offset: 25px;

.filter-holder {
  position: absolute;
  top: 100%;
  height: 0;
  background-color: get_color('neutral', 100);
  z-index: 100;
  width: 100%;
  left: 0;
  transition: top 300ms;
  overflow: hidden;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;

  &.shown {
    top: $modal-top-offset;
    height: calc(100% - #{$modal-top-offset});
  }

  .filter-handle-button-holder {
    min-height: 50px;
  }

  .item-icon {
    height: 16px;
    min-width: 16px;

    svg g rect {
      fill: get_color('neutral', 0);
    }
  }

  .cross-icon {
    svg {
      width: 20px;
      height: 20px;
    }

    path:last-child {
      fill: get_color('primary', 50);
    }
  }

  .bp3-icon-chevron-left svg {
    width: 20px !important;
    height: 20px !important;

    path {
      fill: get_color('primary', 50) !important;
    }
  }

  .compact-select-button {
    background-color: get_color('primary', 50);
    color: get_color('neutral', 100);
  }
}
