@import '~/client/src/shared/theme.module';

.logistics-filter-count-label {
  border-radius: 9px;
  background-color: get_color('primary', 50);
  color: get_color('neutral', 100);
  width: 18px;
  height: 18px;
}

.common-icon svg {
  width: 22px;
  height: 22px;
  padding: 2px;
}
