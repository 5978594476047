@import '~/client/src/shared/theme.module';

.announcement-text-content {
  .title-row {
    padding-top: 40px;
    width: 720px;
    margin: auto;
    align-items: flex-start;
  }

  .announcement-title-icon {
    margin-left: -30px;
    margin-top: 6px;

    svg {
      transform: scale(1.3);
      circle {
        fill: get_color('error', 40);
      }
    }

    &.empty-title svg circle {
      color: get_color('primary', 0, 0.38);
    }
  }

  .announcement-title {
    resize: none;
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;
    padding: 0 6px;

    &:focus {
      outline: none;
    }
  }

  .bp3-icon.bp3-icon-chevron-left svg {
    width: 30px;
    height: 30px;

    path {
      fill: get_color('neutral', 60);
    }
  }

  .announcement-text-editor {
    .ql-container {
      border: none;
      font-size: 16px;
      min-height: 300px;

      .ql-editor {
        width: 720px;
        min-height: 400px;
        cursor: text;
        padding: 0;
        margin: 12px 6px;
      }
    }
  }
}
