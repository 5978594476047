@import '~/client/src/shared/theme.module';

.project-block {
  flex-direction: column;
  .project-name {
    margin-bottom: 1px;
    font-weight: 600;
    border-bottom: 2px solid get_color('neutral', 100);

    &.active {
      border-bottom-color: get_color('primary', 30);
    }
  }
  .project-address,
  .project-schedule {
    justify-content: flex-end;
    font-size: 12px;
    color: get_color('neutral', 50);
  }
  .project-status-bar {
    justify-content: flex-end;
  }

  &:last-child {
    padding-bottom: 0;
  }
  &:focus {
    .project-name {
      border-bottom-color: get_color('primary', 30);
    }
  }
}
