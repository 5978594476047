@import '~/client/src/shared/theme.module';

.user-directory-content {
  .band-row .hierarchy-delivery-attribute-tag svg {
    color: get_color('neutral', 100) !important;
  }

  .item-row {
    &:hover {
      background-color: get_color('primary', 92, 0.5);
    }

    &.selected {
      background-color: get_color('primary', 92);
    }
  }
}

.filter-button {
  svg,
  path {
    fill: currentColor;
    width: 16px;
    height: 16px;
  }

  &.selected {
    background-color: hsla(211, 76%, 94%);
  }
}
