@import '~/client/src/shared/theme.module';

.color-picker {
  display: inline-block;
}

.color-picker-button {
  display: inline-block;

  .chevron-right {
    vertical-align: middle;
  }
}

.color-option {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 2px;
  border-radius: 2px;
  vertical-align: middle;
  cursor: pointer;
  &.selected {
    border: 1px solid get_color('primary', 30);
  }
}

.color-picker-wrapper.bp3-popover {
  box-shadow: none;
}
