@import '~/client/src/shared/theme.module';

$workflows-summary-height: 40px;

.logistics-list {
  .workflows-summary {
    top: 50px;
    max-height: $workflows-summary-height;
    min-height: $workflows-summary-height;
    .workflow-icon svg {
      fill: get_color('grey', 30);
      width: 24px;
      height: 24px;
      padding: 2px;
    }
  }
}
