@import '~/client/src/shared/theme.module';

.page-header-container {
  padding: 0px;
  height: 48px;
  background-color: get_color('neutral', 96);
  border-bottom: get_color('neutral', 84);
  padding-left: 21px;
  padding-right: 16px;

  .menu-trigger {
    svg {
      width: 18px;
      height: 18px;
      path {
        fill: get_color('neutral', 0);
      }
    }
  }
}
