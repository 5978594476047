@import '~/client/src/shared/theme.module';

.view-selector {
  display: inline-flex;

  .view-item {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid get_color('neutral', 80);
    margin-inline: 4px;

    &.selected {
      i svg path {
        fill: get_color('primary', 20);
      }
      border: 1px solid get_color('primary', 20);
      background-color: get_color('primary', 90);
    }

    i {
      display: inline-flex;

      svg {
        width: 18px;
        height: 18px;

        path {
          fill: get_color('neutral', 10);
        }
      }
    }
  }
}
