@import '~/client/src/shared/theme.module';

.channel-container {
  .channel-action-bar {
    min-height: 30px;
    border-radius: 10px;
    padding-top: 6px;
    padding-left: 10px;
  }

  .chat-send-btn {
    min-width: 30px;
    height: 30px;

    background-color: get_color('primary', 50);
    border: none;
    border-radius: 50%;

    color: get_color('neutral', 100);
    font-weight: 600;
  }
}
