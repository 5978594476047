@import '~/client/src/shared/theme.module';

.building-levels-selector {
  .p-checkbox-box {
    background: get_color('neutral', 100) !important;
    border-color: get_color('neutral', 50) !important;
    color: get_color('neutral', 50) !important;
  }

  .checked {
    .p-checkbox-box {
      background: get_color('primary', 50) !important;
      border-color: get_color('primary', 50) !important;
      color: get_color('neutral', 100) !important;
    }
  }

  &-submit {
    background-color: get_color('primary', 50);
    color: get_color('neutral', 100);
  }
}
