@import '~/client/src/shared/theme.module';

.notification-delivery-actions {
  min-height: 20px;

  .btn {
    background-color: transparent;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px 15px;
    border-radius: 5px;
    margin-right: 10px;
    font-weight: normal;
    flex-grow: 0;

    &-red {
      color: get_color('error', 50);
      border: 1px solid get_color('error', 50);

      &.active {
        color: get_color('neutral', 100);
        background-color: get_color('error', 50);
      }
    }

    &-primary-blue {
      color: get_color('primary', 30);
      border: 1px solid get_color('primary', 30);

      &.active {
        color: get_color('neutral', 100);
        background-color: get_color('primary', 30);
      }
    }

    &.loading {
      opacity: 0.65;
    }

    svg {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}
.status-separator {
  height: 1px;
  border-bottom: 1px solid get_color('primary', 30);
}
