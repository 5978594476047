@import '~/client/src/shared/theme.module';

.entity-name-filter {
  .search-container {
    .search {
      outline: none;
      border: none;
      padding: 0;
      max-width: 100%;
      max-width: 100%;
      height: 100%;
      transition-duration: 0.4s;
      -moz-transition-duration: 0.4s;
      -webkit-transition-duration: 0.4s;
      -o-transition-duration: 0.4s;
      &.shown {
        max-width: 80vw;
        max-width: 80vw;
      }
    }
  }
}
