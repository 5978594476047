@import '~/client/src/shared/theme.module';

.qr-codes-scanner-active {
  bottom: 0px;
  z-index: 100;
  background-color: get_color('neutral', 100);
  width: 100%;
  &.hidden-scanner {
    padding: 7px 20px !important;
  }

  &.compact {
    left: 0 !important;
    width: 100% !important;
  }
  .base-action-btn {
    height: 40px;
    border-radius: 100px;
    gap: 8px;
  }

  .base-action-btn-title {
    padding: 10px !important;
  }
}

.scanner-selector {
  height: 40px;
  padding-left: 12px;
  padding-right: 8px;
}

.selected-tab {
  position: relative;

  &::after {
    background-color: get_color('primary', 30);
    bottom: 0;
    content: '';
    display: block;
    height: 3px;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0);
    width: 29px;
    border-top-right-radius: 100px;
    border-top-left-radius: 100px;
  }
}

.dimmer {
  position: absolute;
  &.shown {
    opacity: 0.4;
    pointer-events: auto;
  }
}

.multi-grid-container {
  .cell {
    padding: 0 8px;
  }

  .category-cell {
    font-weight: 500;
  }

  .cell-header {
    font-size: 14px;
    text-transform: capitalize;

    color: get_color('neutral', 30);
  }

  .collapsed {
    width: calc(100vw - 24px);
    max-width: calc(100% - 24px);
  }

  .reports-header-btn {
    color: get_color('neutral', 30);
  }
}

#qr-shaded-region {
  border-style: solid;
  border-color: get_color('neutral', 0, 0.08) !important;

  div {
    display: none;
  }
}

#qr-shaded-region::before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid get_color('neutral', 96);
  border-radius: 4px;
}
