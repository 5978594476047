@import '~/client/src/shared/theme.module';

.location-card-holder {
  width: 335px;
  z-index: 1;
  box-shadow: 0 0 7px 0 get_color('neutral', 0);
  .calendar-icon svg path {
    fill: get_color('error', 50);
  }
}
