@import '~/client/src/shared/theme.module';

.select-date-modal {
  display: none;
  position: absolute;
  background-color: get_color('neutral', 100);
  z-index: 100;
  width: 100%;
  bottom: 0;
  left: 0;

  opacity: 0;
  transition: all 400ms;

  &.shown {
    display: block;
    opacity: 1;
  }

  .cross-icon,
  .arrow-back-icon {
    display: inline-flex;

    svg {
      width: 26px;
      height: 26px;

      path:last-child {
        fill: get_color('neutral', 50);
      }
    }
  }

  .selection-button {
    right: 10px;
    width: 200px;
    height: 48px;
    box-shadow: 0 5px 12px get_color('primary', 0, 0.6);
    z-index: 2;
  }

  .month-label {
    display: flex;
    cursor: pointer;

    .caret-icon {
      width: 35px;
      padding-top: 2px;
      padding-left: 8px;

      svg path {
        fill: get_color('neutral', 40);
      }
    }
  }

  .month-navigation {
    height: 18px;
    svg {
      height: inherit;
      path {
        fill: get_color('primary', 30);
        fill-opacity: 1;
      }
    }
  }

  .view-bookings {
    z-index: 300;
    bottom: 0;
    top: 0;

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .hour-labels-container {
      flex: 1 1 45px;
      .col {
        .text {
          font-weight: 400;
          font-size: 12px;
          line-height: 12px;
          color: get_color('primary', 0, 0.6);
        }
        > div:nth-child(2) {
          display: none;
        }
      }
    }
  }
}

.timepicker-wrapper {
  bottom: 0;
  z-index: 200;

  .timepicker .choice-line {
    width: 80%;
  }

  .circular-select {
    $width: 75px;

    .picker {
      position: relative;
      overflow: hidden;
      width: $width;
    }

    .spinner {
      cursor: pointer;
      line-height: 2;
      height: 190px;
      overflow: hidden;
      position: relative;
      text-align: center;
      padding-top: 60px;

      &-items {
        list-style: none;
        user-select: none;

        &.animate {
          transition: transform 0.15s;
        }
      }

      .item {
        width: $width;
        opacity: 0.25;
        transition: opacity 0.1s;
        font-size: 20px;
        height: 32px;

        &.active {
          font-weight: 600;
          opacity: 1;
        }
        &.neighbor-1 {
          opacity: 0.7;
        }
        &.neighbor-2 {
          opacity: 0.5;
        }
        &.neighbor-3 {
          opacity: 0.3;
        }
      }

      input {
        display: block;
        position: absolute;
        height: 28px;
        width: 40px;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        appearance: none;
        text-align: inherit;
        background: transparent;
        border: none;
        padding: 0;

        &:focus {
          background: transparent;
          outline: none;
          border-bottom: 1px solid;
        }
      }
    }
  }
}

.save-button {
  background-color: get_color('primary', 30);
}
