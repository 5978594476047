@import '~/client/src/shared/theme.module';

$large-size: 100px;
$small-size: 32px;
$tiny-size: 28px;
$initials-font-size: 14px;
$enormous-size: 172px;

.avatar-container {
  background-color: get_color('grey', 88);

  &.initials-wrapper {
    background: get_color('secondary', 88);
  }

  &.enormous {
    width: $enormous-size;
    height: $enormous-size;
    .initials {
      font-size: calc($enormous-size / 2);
    }
  }

  //? TODO: make s56, sX

  &.large {
    width: $large-size;
    height: $large-size;
    .initials {
      font-size: calc($large-size / 2);
    }
  }

  &.small {
    width: $small-size;
    height: $small-size;
    .initials {
      font-size: $initials-font-size;
    }
  }

  &.tiny {
    width: $tiny-size;
    height: $tiny-size;
    .initials {
      font-size: calc($initials-font-size / 1.5);
    }
  }

  img {
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  .avatar-placeholder svg {
    transform: scale(0.7);
  }

  .avatar-status {
    width: 12px;
    height: 12px;
    background: get_color('neutral', 60);
    border: 2px solid get_color('neutral', 100);
    position: absolute;
    bottom: 2%;
    right: 2%;
    border-radius: 100%;

    &.online {
      background: get_color('green', 60);
    }
  }
}
