@import '~/client/src/shared/theme.module';

$size: 16px;

.select-button {
  width: $size;
  height: $size;
  background-color: get_color('neutral', 100);

  .bp3-icon svg {
    fill: get_color('primary', 30);
    margin-left: -5px;
  }
}
