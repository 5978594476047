@import '~/client/src/shared/theme.module';

.notification-filter {
  &-item {
    padding: 0 5px;
    border: none;
    background-color: transparent;
    flex: 0 1 70px;
    font-weight: 600;
    white-space: nowrap;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    i {
      display: flex;
      margin-right: 3px;
    }

    &.flag-font {
      color: get_color('error', 50);
    }

    &.rfi-font {
      color: get_color('error', 50);
    }

    &.schedule-comment-font {
      color: get_color('error', 50);
    }

    &.blue-font {
      color: get_color('primary', 30);
    }

    &.active {
      background-color: get_color('grey', 84);
    }

    &.grey-font {
      color: get_color('neutral', 70);

      &.blue-font {
        path {
          stroke: get_color('neutral', 70);
          fill: get_color('neutral', 70);
        }

        circle {
          stroke: get_color('neutral', 70);
        }
      }

      &.rfi-font {
        rect {
          fill: unset;
          stroke: get_color('neutral', 70);
        }

        path {
          fill: get_color('neutral', 70);
        }
      }

      rect {
        fill: get_color('neutral', 70);
      }
    }
  }
}

.spinner-holder {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.notification-list {
  i {
    display: inline-flex;
  }

  .notification-read {
    background-color: get_color('grey', 96);
  }

  .notification-item {
    overflow-x: hidden;
    display: flex;
    width: 100%;

    &-content {
      flex-basis: 100%;
      display: flex;
      flex-shrink: 0;
    }

    &-swipe-area {
      padding: 0 60px;
      background-color: get_color('primary', 30);
      display: flex;
      color: get_color('neutral', 100);
    }
  }
}
