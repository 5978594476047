@import '~/client/src/shared/theme.module';

.base-sitemap {
  .location-icon-grey {
    svg path {
      fill: get_color('neutral', 70);
    }
    &-stroke {
      svg path {
        stroke: get_color('neutral', 70);
      }
      svg rect {
        stroke: get_color('neutral', 70);
      }
    }
  }

  .georeferencing-tooltip {
    background-color: get_color('neutral', 100);
    padding: 20px;
    border-radius: 20px;
    width: 540px;
    position: absolute;
    z-index: 10;
    top: calc(50% - 200px);
    left: calc(50% - 270px);
  }

  .location-objects-tooltip {
    background-color: get_color('neutral', 100);
    padding: 20px;
    border-radius: 20px;
    width: 540px;
    position: absolute;
    z-index: 10;
    top: calc(50% - 325px);
    left: calc(50% - 270px);
  }

  .map-controls-instruction {
    height: 40px;
  }

  .location-name {
    max-width: 100px;
    min-width: 100px;
  }

  .georeference-instructions {
    background-color: get_color('neutral', 100);
    padding: 20px;
    border-radius: 20px;
    width: 540px;
    position: absolute;
    z-index: 10;
    top: calc(50% - 290px);
    left: calc(50% - 270px);
  }

  .submit-project-button {
    &.light {
      background-color: get_color('primary', 50);
      border: 1px solid get_color('primary', 50);

      &:hover {
        background-color: get_color('neutral', 100);
        color: get_color('primary', 50);
      }
    }
    border: 1px solid get_color('primary', 30);
    border-radius: 3px;
    color: get_color('neutral', 100);
    background-color: get_color('primary', 30);
    text-align: center;
    cursor: pointer;

    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    &:hover {
      background-color: get_color('neutral', 100);
      color: get_color('primary', 30);
    }
  }

  .delivery-sitemap {
    overflow: hidden;
  }
}

.grab-area {
  cursor: grab;
  left: 0;
  top: 0;

  &.grabbing-cursor {
    cursor: grabbing;
  }
}

.show-on-holder {
  bottom: 10px;
  left: 10px;
  z-index: 1000;
  box-shadow: 0px 0px 5px 0px get_color('neutral', 0);
}
