@import '~/client/src/shared/theme.module';

.inspection-popup-form {
  display: flex;
  z-index: 9 !important;
  bottom: 0;
  max-height: 0;

  &.shown {
    transition: all 300ms !important;
    max-height: calc(100% - 25px) !important;
    top: unset !important;
    height: auto !important;
  }
}
