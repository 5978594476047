@import '~/client/src/shared/theme.module';

.mobile-super-store {
  .super-store-header {
    border-top: 1px solid get_color('neutral', 0);
    border-bottom: 1px solid get_color('neutral', 0);
    background-color: get_color('neutral', 84);
    height: 30px;
  }
  .filter-option-main:hover {
    background-color: unset !important;
  }
  .filter-option-checkbox-container {
    .p-checkbox-box {
      background-color: get_color('neutral', 100);
    }
  }
  .filter-option {
    min-height: 50px;
    .desk {
      font-size: 16px;
      color: get_color('neutral', 0);
    }
  }
}
