@import "~/client/src/shared/theme.module";

.rfi-icon-grey {
  rect {
    stroke: get_color('neutral', 70);
  }
  path {
    fill: get_color('neutral', 70);
  }
}
