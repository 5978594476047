.animatable {
  background: inherit;
  will-change: transform;
  transition: transform 0.2s ease;
  transform: translateX(0);

  &.prepare {
    /* we do this so we can tell when the "preparing" was done
       since there seems to be a browser bug where the preparation still takes
       time, even with `transition: none;` */
    transition-duration: 0.0000000000001s;
    opacity: 0;

    &.from-left {
      transform: translateX(-110%);
    }
    &.from-right {
      transform: translateX(110%);
    }
  }
}
