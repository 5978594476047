@import '~/client/src/shared/theme.module';

$border-radius: 4px;
$modal-top-offset: 30px;
$icon-size: 16px;

.filter-holder {
  position: fixed;
  bottom: 0;
  height: 0;
  background-color: get_color('neutral', 100);
  z-index: 102;
  width: 100%;
  left: 0;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  display: none;

  &.shown {
    top: $modal-top-offset;
    // When using vh we get the wrong size on mobile devices. For such cases it is better to use dynamic vh(dvh)
    height: calc(100dvh - $modal-top-offset);
    display: flex;

    .with-banner & {
      height: calc(100dvh - $modal-top-offset - $DEFAULT_BANNER_HEIGHT);
    }
  }

  .filter-handle-button-holder {
    min-height: 50px;
  }

  .item-icon {
    svg {
      min-height: $icon-size;
      min-width: $icon-size;
      max-height: $icon-size;
      max-width: $icon-size;
      fill: get_color('neutral', 0);
      color: get_color('neutral', 0);
    }
  }

  .cross-icon {
    svg {
      width: 20px;
      height: 20px;
    }

    path:last-child {
      fill: get_color('primary', 50);
    }
  }

  .bp3-icon-chevron-left svg {
    width: 20px !important;
    height: 20px !important;

    path {
      fill: get_color('primary', 50) !important;
    }
  }

  .compact-select-button {
    background-color: get_color('primary', 50);
    color: get_color('neutral', 100);
  }
}
