@import '~/client/src/shared/theme.module';

.sidebar-header {
  .arrow-down {
    position: absolute;
    right: 8px;

    svg,
    path {
      fill: get-color('neutral', 30);
    }
  }
}
