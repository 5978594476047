@import '~/client/src/shared/theme.module';

.site-permit-creation-form {
  .cross-icon {
    display: inline-flex;

    svg path:last-child {
      fill: get_color('neutral', 60);
    }
  }

  .apply-button {
    height: 48px;
    border-radius: 4px;
    background-color: get_color('primary', 50);
  }

  .compact-popup-modal + .dimmer.shown {
    height: 100%;
  }

  .compact-confirm-dialog {
    .apply-button {
      border-radius: 6px;
      background-color: unset;
      height: unset;
    }
  }
}
