@import '~/client/src/shared/theme.module';

.activity-log {
  margin: 0;
  background: get_color('neutral', 100);
  font-size: 14px;
  line-height: 1.5;
  color: get_color('neutral', 100, 0.4);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  list-style: none;
}
.activity-log::-webkit-scrollbar {
  width: 0;
}

.activity-log {
  margin: 0;
  background: get_color('neutral', 100);
  font-size: 14px;
  line-height: 1.5;
  color: get_color('neutral', 100, 0.4);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  list-style: none;

  & h3 {
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.88;
    letter-spacing: normal;
    text-align: left;
    color: get_color('neutral', 20);
    margin: 0 0 10px 0;
  }

  & &-list {
    padding: 0;
    margin: 0;
  }

  & &-item {
    position: relative;
    padding: 0 0 15px;
    list-style: none;
    margin: 0;
    font-size: 14px;

    &-author {
      font-size: 12px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: get_color('neutral', 20);
      margin: 0 0 10px 0;
    }

    &-date {
      font-size: 11px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: get_color('neutral', 70);
      margin: 0 0 10px 0;
    }

    &-text {
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.29;
      letter-spacing: normal;
      text-align: left;
      color: get_color('neutral', 20);
      margin: 0;
    }
  }

  & &-item-head {
    position: absolute;
    left: 5px;
    width: 12px;
    height: 12px;
    background-color: get_color('neutral', 100);
    border-radius: 100px;
    border: 2px solid get_color('neutral', 80);
  }

  & &-item-tail {
    position: absolute;
    left: 10px;
    top: 12px;
    height: 100%;
    border-left: 2px dashed get_color('neutral', 80);
  }

  & &-item-content {
    padding: 0 0 0 15px;
    position: relative;
  }

  & &-container {
    line-height: 1.5;
  }
}
