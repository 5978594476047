@import '~/client/src/shared/theme.module';

.workflow-card-location-row {
  overflow: hidden;
  i {
    width: 16px !important;
    height: 16px !important;
  }
  &.one-color {
    i {
      color: get_color('purple', 40) !important;
      fill: get_color('purple', 40) !important;
    }
    i.stroke-icon {
      stroke: get_color('purple', 40) !important;
    }
  }
  .location-row-chain {
    color: get_color('neutral', 70);
  }
}
