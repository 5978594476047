@import '~/client/src/shared/theme.module';

$tabs-panel-height: 44px;
$spinner-size: 50px;

.activity-details {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: get_color('neutral', 100);
  border-left: 1px solid get_color('neutral', 84);

  .location-picker,
  .compact-companies-directory,
  .user-directory {
    z-index: 100;
    .user-profile-preview-additional-info {
      display: flex;
    }
  }

  &-content {
    padding-bottom: $tabs-panel-height;
  }

  &-tabs {
    height: $tabs-panel-height;

    .tab {
      height: 36px;
      border-radius: 8px 8px 0px 0px;

      &-text {
        color: get_color('neutral', 0, 0.75);
      }
    }

    .active {
      background-color: get_color('primary', 40, 0.08);
      border-bottom: 2px solid get_color('primary', 40);

      .tab-text {
        color: get_color('primary', 40);
      }
    }
  }

  .loader {
    position: absolute;
    z-index: 3;
    top: calc(50% - #{calc($spinner-size / 2)});
    left: calc(50% - #{calc($spinner-size / 2)});
  }

  .link:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .workflow-card-location-row {
    margin-bottom: 5px;
    .location-row-chain {
      margin-left: 5px;
    }
  }

  .activity-log-section {
    .user-profile-preview .name-row {
      font-weight: bold;
    }
    .file-preview {
      width: 100%;
      height: 100%;
      img {
        width: inherit;
        height: inherit;
      }
    }
  }
}

.linked-field-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.data-linking-state-wrapper {
  width: 25px;
  height: 25px;
  margin-left: 10px;
  margin-top: 7px;
}

.linking-settings-header {
  display: flex;
  justify-content: space-between;
}

.linking-icon {
  &.enabled {
    svg path {
      fill: get_color('primary', 20);
    }
  }
  &.disabled {
    svg path {
      fill: get_color('neutral', 70);
    }
  }
}

.dimmer {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  background: get_color('neutral', 0);
  z-index: 9;
  bottom: 0px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 500ms;
  will-change: opacity;

  &.shown {
    opacity: 0.4;
    pointer-events: auto;
  }
}
