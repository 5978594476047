@import '~/client/src/shared/theme.module';

.expandable-workflow-card {
  .delivery-company-title {
    max-width: 230px;
  }

  .delivery-workflow-card-icon {
    $icon-container-size: 20px;
    height: $icon-container-size !important;
    width: $icon-container-size !important;

    i,
    i svg {
      $icon-size: 14px;
      width: $icon-size !important;
      height: $icon-size !important;
    }
  }

  .delivery-concrete-direct-icon,
  .delivery-concrete-direct-icon i,
  .delivery-concrete-direct-icon i svg {
    width: 24px !important;
    height: 24px !important;
  }

  .delivery-field-icon {
    margin-right: 4px;
  }

  .no-fill {
    .delivery-field-icon.with-color,
    .delivery-field-icon.with-color svg,
    .delivery-field-icon.with-color svg path {
      fill: currentColor !important;
    }

    .delivery-field-icon.gate,
    .delivery-field-icon.gate svg {
      color: inherit !important;
    }
  }

  .expandable-arrow {
    bottom: 0;
    right: 0;
    z-index: 10;

    > span:hover {
      background: get_color('primary', 96);
    }
  }
}
