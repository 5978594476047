@import '~/client/src/shared/theme.module';

.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
}
