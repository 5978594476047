@import '~/client/src/shared/theme.module';

$icon-size: 20px;
$arrow-icon-size: 15px;
$height: 60px; //! Keep at least 60 px to avoid overlapping 'line control' on IPhone without button

.bottom-navigation-nav-bar {
  &-scrollable {
    height: $height;
    background-color: get_color('neutral', 96);
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: space-between;
  }

  i,
  svg {
    width: $icon-size;
    height: $icon-size;
  }

  .arrow {
    top: 6px;
    right: 6px;

    svg {
      width: $arrow-icon-size;
      height: $arrow-icon-size;
    }
  }

  .arrow .close svg,
  i,
  svg {
    fill: get_color('neutral', 50);
  }

  .fill-path {
    path {
      fill: get_color('neutral', 50);
    }
  }

  .deliveries-icon path {
    stroke: get_color('neutral', 50);
  }

  .selected .deliveries-icon path {
    stroke: get_color('secondary', 40);
  }

  .arrow .open,
  .selected {
    .text {
      color: get_color('secondary', 40);
    }

    svg path {
      fill: get_color('secondary', 40);
    }
  }

  .navigate-button {
    width: 75px;
  }

  .menu-popup {
    width: 100%;
    z-index: 100;
    bottom: $height;
    background-color: get_color('neutral', 96);
    border-radius: 4px 4px 0 0;
    max-height: 400px;
    overscroll-behavior: none;
    border-top: 1px solid get_color('grey', 84);

    .permit-icon {
      svg {
        height: 22px;
        width: 22px;
        color: get_color('neutral', 50);
      }
    }

    .menu-button {
      height: $height;

      &.first {
        border-radius: 10px 10px 0px 0px;
      }

      &.last {
        border-radius: 0px 0px 10px 10px;
      }

      &.selected {
        background-color: get_color('primary', 96) !important;

        .deliveries-icon path {
          stroke: get_color('primary', 30) !important;
        }

        .text {
          color: get_color('primary', 30) !important;
        }

        svg path {
          fill: get_color('primary', 30) !important;
        }
      }
    }
  }

  .create-icon {
    svg path {
      fill: get_color('neutral', 100);
    }

    background-color: get_color('primary', 30);
  }
}
