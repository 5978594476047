@import '~/client/src/shared/theme.module';

.user-profile-preview {
  .hierarchy-delivery-attribute-tag {
    max-width: calc(100% - 8px);
  }

  &.container {
    padding-top: 12px;
    text-align: center;

    .user-name {
      font-size: 32px;
      line-height: 40px;
      position: relative;

      span {
        font-weight: 400 !important;
      }

      .dot {
        width: 15px;
        height: 15px;
        background: get_color('neutral', 60);
        border: 2px solid get_color('neutral', 100);
        position: absolute;
        bottom: 14px;
        right: -25px;
        border-radius: 100%;

        &.online {
          background: get_color('green', 20);
        }
      }
    }

    .brief-info {
      font-size: 21px;
      line-height: 28px;
      color: get_color('grey', 40);
      margin-top: 8px;
    }
  }
}
