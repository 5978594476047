@import '~/client/src/shared/theme.module';

.delivery-details-content {
  .delivery-details-image-input {
    .file-preview {
      width: 100%;
      height: unset;
      flex-grow: 1;
    }
  }

  .weather {
    min-height: 40px;
    background-color: get_color('neutral', 96);
    margin: 16px 0 !important;

    .icon {
      fill: get_color('primary', 30);
    }
  }

  .selected-option-icon {
    $option-icon-size: 18px;

    width: $option-icon-size;
    height: $option-icon-size;
    display: flex;
    flex-basis: $option-icon-size;

    $option-icon-color: get_color('primary', 0, 0.38);

    svg {
      width: $option-icon-size;
      height: $option-icon-size;

      color: $option-icon-color !important;
      fill: $option-icon-color !important;
    }
  }

  .delivery-log-section {
    .user-profile-preview .name-row {
      font-weight: 600;
    }
    .file-preview {
      width: 100%;
      height: 100%;
      img {
        width: inherit;
        height: inherit;
      }
    }
  }

  .primary-blue-switch {
    margin-bottom: 0px !important;

    .bp3-control-indicator::before {
      background: get_color('neutral', 100);
    }
  }

  .input-wrapper {
    @mixin inline($count) {
      display: inline-flex;
      vertical-align: top;

      width: calc(100% / #{$count});
    }

    &.inline {
      &-2 {
        @include inline(2);
      }

      &-3 {
        @include inline(3);
      }

      // etc...
    }

    .location-label-tag .hierarchy-delivery-attribute-tag-icon {
      align-items: flex-start !important;
      padding-top: 1px !important;
    }
  }
}
