@import '~/client/src/shared/theme.module';

.multi-grid-container {
  .cell {
    padding: 0 12px;

    &.category-cell {
      &-name {
        overflow: visible;
        z-index: 1;
        white-space: nowrap;
      }

      svg {
        color: get_color('neutral', 60);
      }
    }

    &-header {
      &:hover {
        background-color: get_color('grey', 96);
      }

      &.sorted {
        background-color: get_color('neutral', 70);
      }
    }

    &.done {
      color: get_color('neutral', 60);
    }

    &.updating {
      font-weight: 600;
      pointer-events: none;
      opacity: 0.5;
    }

    &.no-padding {
      padding: 0;
    }

    .hierarchy-delivery-attribute-tag {
      display: flex;
    }
  }

  .checkbox-selector .p-checkbox-box {
    background: get_color('neutral', 100) !important;
    border-color: get_color('neutral', 70) !important;
    color: get_color('neutral', 50) !important;
  }

  .selection-pop-up {
    position: absolute;
    left: 24px;
    top: 31px;
    width: 150px;
    border: 1px solid get_color('slate', 90);
    background-color: get_color('neutral', 100);
    border-radius: 0 4px 4px 4px;
    z-index: 1;
  }

  .resize-cursor-holder {
    width: 12px;
    z-index: 3;
    top: 0;
    right: -6px;
    &:hover {
      background-color: get_color('primary', 40);
      opacity: 0.62;
    }
    &:active {
      background-color: get_color('primary', 40);
      opacity: 0.62;
    }
  }
}
