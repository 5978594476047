@import '~/client/src/shared/theme.module';

$spinner-size: 50px;
$min-field-height: 48px;
$tabs-panel-height: 44px;

@mixin delivery-action-button {
  border: 1px solid get_color('primary', 30);
  border-radius: 3px;
  color: get_color('neutral', 100);
  background-color: get_color('primary', 30);
  text-align: center;
  padding: 5px;
  margin: 0 5px;
  cursor: pointer;

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &:hover {
    background-color: get_color('neutral', 100);
    color: get_color('primary', 30);
  }
}

.loader-holder {
  position: absolute;
  z-index: 6;
  background-color: get_color('neutral', 70, 0.4);

  .loader {
    position: absolute;
    z-index: 6;
    top: calc(50% - #{calc($spinner-size / 2)});
    left: calc(50% - #{calc($spinner-size / 2)});
  }
}

.delivery-details-view > .dimmer {
  z-index: 21 !important;
}

.delivery-details {
  display: flex;
  justify-content: center;

  .hierarchy-chains-container {
    height: 18px;
  }

  &-content {
    padding-bottom: $tabs-panel-height;

    .duplicate-delivery-wrapper,
    .duplicate-delivery-btn,
    .duplicate-delivery-btn > i {
      height: 24px;
      width: 24px;
    }
    .duplicate-delivery-btn {
      &:hover {
        background-color: get_color('primary', 96);
      }
    }
    .btn-action {
      padding: 0;
      min-width: 65px;
      height: 19px;
      white-space: nowrap;

      font-size: 12px;
      border-radius: 10px;
      border: 1px solid;

      color: get_color('primary', 30);
      background-color: get_color('neutral', 100);

      &.disabled {
        pointer-events: none;
        color: get_color('neutral', 84);
      }

      &:hover {
        background-color: get_color('primary', 84, 0.7);
      }
    }

    .sitemap-icon {
      height: 32px;
      width: 32px;
      display: inline-block;
      border: 1px solid get_color('neutral', 60);
      border-radius: 4px;
      padding: 6px 5px;

      svg {
        height: 21px;
        width: 20px;
      }
    }

    .action-button {
      @include delivery-action-button;
    }

    .draggable-bar {
      bottom: 0 !important;
    }

    .delivery-log-section .photo-thread .changed-status-message {
      max-width: 315px !important;

      &-old-value {
        max-width: 230px !important;
      }
    }
    .multi-value-input .multi-phone-number-input {
      max-width: 334px;
    }
  }

  &-footer {
    .add-button svg {
      height: 20px;
      width: 20px;

      path:last-child {
        fill: get_color('neutral', 50);
        stroke: get_color('neutral', 50);
      }
    }

    .action-button {
      @include delivery-action-button;
    }
    .btn {
      width: 100%;
      font-weight: 600;
      border: none;
      background-color: get_color('neutral', 100);
      outline: none;

      &-reject {
        color: get_color('error', 50);
      }

      &-view {
        display: flex;
        align-items: center;
        justify-content: center;

        color: get_color('neutral', 50);

        svg {
          margin-left: 5px;
        }
      }

      &-submit {
        color: get_color('primary', 30);
        &:disabled {
          color: get_color('neutral', 84);
        }
      }

      &-accept {
        color: get_color('primary', 30);
      }

      &.disabled {
        color: get_color('neutral', 84);
      }
    }
  }

  &-tabs {
    height: $tabs-panel-height;

    .tab {
      height: 36px;
      border-radius: 8px 8px 0px 0px;

      &-text {
        color: get_color('neutral', 0, 0.75);
      }
    }

    .active {
      background-color: get_color('primary', 40, 0.08);
      border-bottom: 2px solid get_color('primary', 40);

      .tab-text {
        color: get_color('primary', 40);
      }
    }
  }
}

.sitemaps-gallery {
  z-index: 10;
  background-color: get_color('neutral', 96);
  top: 0;
  left: 0;

  .arrows-holder {
    right: 60px !important;
  }

  .cross-icon {
    width: 32px;
    height: 32px;
    display: inline-block;
    border: 1px solid get_color('neutral', 60);
    border-radius: 16px;
    padding: 3px;
    background-color: get_color('neutral', 100);
    position: absolute;
    z-index: 11;
    right: 12px;
    top: 4px;
    box-shadow: 0px 0px 34px 0px get_color('neutral', 0, 0.25);

    svg {
      width: 26px;
      height: 25px;

      path:last-child {
        fill: get_color('neutral', 60);
      }
    }
  }

  .tags-list {
    top: 8px;
    left: 12px;
    z-index: 12;

    .tags-holder {
      transform: scale(1.2);
      transform-origin: top left;

      .attribute-tag-holder {
        margin-bottom: 4px;
      }

      .hierarchy-delivery-attribute-tag::before {
        content: '';
        position: absolute;
        display: inline-block;
        left: 0;
        top: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-color: get_color('neutral', 100);
      }
    }
  }
}

.supplier-section {
  z-index: 1;
}
