@import '~/client/src/shared/theme.module';

.activity-log-bold-text {
  color: get_color('neutral', 0);
  font-weight: 600;
}

.primary-column {
  flex: 110px 0 0;
  display: flex;
  justify-content: flex-end;
}

.common-column {
  flex: 90px 0 0;
  display: flex;
  justify-content: center;
}

.table-heder-cell {
  border-bottom: 1px solid get_color('grey', 40);
}

.status-update-text-container {
  .company-name {
    max-width: 150px;
  }

  .light-blue {
    .status-update-icon {
      circle {
        stroke: get_color('primary', 70);
      }

      path {
        fill: get_color('primary', 70);
      }
    }

    .manpower-icon * {
      stroke: get_color('primary', 70);
    }

    .value {
      color: get_color('primary', 70);
    }
  }

  .grey {
    .status-update-icon {
      circle {
        stroke: get_color('neutral', 84);
      }

      path {
        fill: get_color('neutral', 84);
      }
    }

    .manpower-icon * {
      stroke: get_color('neutral', 84);
    }

    .value {
      color: get_color('neutral', 84);
    }
  }

  .status-update-icon,
  .manpower-icon {
    height: 18px;
  }
}

.changed-status-message {
  max-width: 320px;
  display: flex;
  flex-wrap: wrap;
}
