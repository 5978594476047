@import '~/client/src/shared/theme.module';

$suggestions-list-height: 250px;
$icon-size: 18px;

.mention-input-container,
.mention-input,
.mention-input__input {
  strong {
    font-family: unset;
  }

  height: auto;
  //padding-bottom: 0;
  border: none;
}

.custom-suggestions-popup {
  position: absolute;
  left: 0;

  width: 100%;
  background-color: get_color('neutral', 100);
  z-index: 100;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .mention-input,
  .mention-input__input {
    &__suggestions {
      position: static !important;

      bottom: 97px;
      top: unset !important;
      z-index: 1000 !important;

      margin-top: 0 !important;
      left: 0 !important;
      background-color: get_color('neutral', 100);
      width: 100%;
    }

    &__suggestions__list {
      max-height: $suggestions-list-height;
      border-top: 1px solid get_color('neutral', 84);
      overflow: auto;

      .mention-option:not(.header-suggestion-row) {
        min-height: 50px;

        &:hover {
          background-color: get_color('primary', 96);

          .username {
            div {
              color: get_color('primary', 30);
            }
          }
        }
      }

      li:first-child .header-suggestion-row {
        border-top: none;
      }
    }

    &__suggestions__item--focused {
      background-color: get_color('primary', 92);
    }

    &__mention {
      padding: 2px 0;
      border-radius: 4px;
      background-color: get_color('primary', 92);
    }
  }

  .close-handler {
    left: 12px;
    color: get_color('neutral', 50);
  }

  .action-handler {
    right: 12px;
  }

  .header-suggestion-row {
    height: 32px;
    background-color: get_color('neutral', 50);

    svg {
      color: get_color('neutral', 100);
      fill: get_color('neutral', 100);
    }
  }

  .hierarchy-delivery-attribute-tag-icon i {
    width: $icon-size !important;
    height: $icon-size !important;
  }
}

.mention-action-button {
  svg {
    height: $icon-size;
    width: $icon-size;
  }

  color: get_color('neutral', 50);
  fill: get_color('neutral', 50);

  &.active {
    color: get_color('primary', 30);
    fill: get_color('primary', 30);
  }
}

.center-action-button {
  right: 50%;
}

.embedded-user-directory {
  left: 0;
  top: 0;
  z-index: 9999;
}
