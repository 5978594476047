@import '~/client/src/shared/theme.module';

.bottom-bar-trigger-button-holder {
  min-width: 40px;
  height: 40px;
  .bottom-bar-trigger-button-icon {
    width: 18px;
    height: 18px;
  }
}

.camera-access-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
}

.action-bar-action-btn {
  &:hover {
    background-color: get_color('neutral', 90);
  }
  &:active {
    background-color: get_color('neutral', 84);
  }
}

.file-preview-holder-limited-width {
  max-width: calc(100% - 20px);
}

.limited-message-width {
  width: calc(100% - 50px);
}

.file-type-icon {
  width: 36px;
  height: 36px;
}

.action-bar-toolbar {
  width: calc(100% - 20px);
  bottom: 0;
}
.action-bar-form {
  height: calc(100% - 40px);
}

.action-bar-form-extended {
  height: calc(100% - 55px);
}

.action-bar-not-focused-extended {
  height: calc(100% - 20px);
}

.preview-image-container {
  border-radius: 10px;
}
