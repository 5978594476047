@import '~/client/src/shared/theme.module';

.timeline {
  &-container {
    z-index: 3;
    pointer-events: none;
  }
  &-dot {
    width: 10px;
    height: 10px;
    top: -4px;
    left: -5px;
    background-color: get_color('error', 50);
  }
  z-index: 2;
  height: 2px;
  background-color: get_color('error', 50);
}
