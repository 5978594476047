@import '~/client/src/shared/theme.module';

.permit-type-selector-container {
  .type-icon {
    color: get_color('secondary', 40);
  }

  .bp3-icon-tick {
    color: get_color('violet', 40);
  }

  .active-type {
    background-color: get_color('primary', 96);

    .type-icon {
      color: get_color('violet', 40);
    }
  }
}
