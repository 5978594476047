@import '~/client/src/shared/theme.module';

.permit-date-picker {
  display: block;
  z-index: 110;
  bottom: 0;
  left: 0;
  padding-bottom: 30px !important;
  max-height: 100% !important;
  border-radius: 10px 10px 0 0;

  .close-icon path {
    fill: get_color('primary', 50);
  }
}
