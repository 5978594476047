@import '~/client/src/shared/theme.module';

.cd-payload-ticket-detailed-info {
  $border-unfilled-value: 1px solid get_color('neutral', 88);
  $border-filled-value: 1px solid get_color('neutral', 0) !important;

  .job-times-indicator-top {
    height: 50%;
    bottom: 50%;
    border-left: $border-unfilled-value;

    &.filled {
      border-left: $border-filled-value;
    }
  }

  .job-times-indicator-bottom {
    height: 50%;
    top: 50%;
    border-left: $border-unfilled-value;

    &.filled {
      border-left: $border-filled-value;
    }
  }

  .job-times-indicator-center {
    width: 8px;
    height: 8px;
    border-radius: 66%;
    border: $border-unfilled-value;
    background: get_color('neutral', 100);
    z-index: 2;

    &.filled {
      border: none !important;
      background: get_color('neutral', 0) !important;
    }
  }
}
