@import '~/client/src/shared/theme.module';

$modal-top-offset: 34%;
$icon-size: 18px;

$option-icon-color: get_color('primary', 0, 0.38);

.delivery-select-modal {
  position: absolute;
  top: 100%;
  height: 0;
  width: 100%;
  left: 0;

  &.is-shown {
    top: 0;
    height: 100%;

    .compact-popup-modal.shown {
      top: $modal-top-offset;
      height: calc(100% - #{$modal-top-offset});

      .clear-handle {
        color: get_color('primary', 30);
        opacity: 0;
      }

      .new-option {
        border-radius: 12px;
        background-color: get_color('neutral', 84);
        height: 24px;
      }

      .select-option {
        min-height: 48px;

        &:hover {
          background: get_color('primary', 96);
        }

        &.selected {
          background: get_color('primary', 92);
        }

        .user-profile-preview-additional-info {
          display: flex;
          flex-direction: row;
          overflow: hidden !important;

          .name-row {
            white-space: nowrap;
          }
        }

        .option-icon {
          width: $icon-size;
          height: $icon-size;
          display: flex;
          flex-basis: $icon-size;

          svg {
            color: $option-icon-color !important;
            fill: $option-icon-color !important;
          }
        }

        .card-title {
          margin-right: 24px;
          max-width: calc(50% - 24px);
        }

        .card-location .hierarchy-delivery-attribute-tag-icon {
          align-items: flex-start !important;
          padding-top: 1px !important;
        }
      }

      .grouping-label-icon {
        width: $icon-size;
        height: $icon-size;
        display: flex;
        flex-basis: $icon-size;

        svg {
          color: get_color('neutral', 100) !important;
        }
      }
    }
  }
}
