@import '~/client/src/shared/theme.module';

.logistics-list-table {
  .section-header {
    background-color: get_color('grey', 90, 1);
    height: 40px;
  }
  .arrow-icon {
    max-width: 30px;
    i svg {
      padding-top: 4px;
      fill: get_color('grey', 30);
      width: 15px;
      height: 15px;
    }
  }
}
