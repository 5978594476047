@import '~/client/src/shared/theme.module';

.filter-section {
  &-title {
    padding-bottom: 2px;
    margin: 0 15px 5px 15px;

    .location-root-option {
      margin-left: 20px;
    }

    .checkbox-container.extra-large {
      overflow: visible;
    }
  }

  &:not(:first-of-type) &-title {
    margin-top: 10px;
  }

  .filter-option {
    background-color: get_color('neutral', 100);
    color: get_color('neutral', 50);
    cursor: default;
    &.no-activities {
      opacity: 0.5;
    }

    &-company {
      max-width: 320px;
      display: block;
      padding: 0 15px;
      color: get_color('primary', 30);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-main {
      padding: 0 20px 0 10px;
      height: 20px;

      &:hover {
        background-color: get_color('primary', 88, 0.5);
        .only-button {
          visibility: visible;
          opacity: 1;
        }
      }
      .only-button {
        max-width: 32px;
        display: inline-block;
        vertical-align: top;
        visibility: hidden;
        opacity: 0;
        margin: 0 4px;
        font-size: 12px;
        color: get_color('neutral', 50);
        border: 1px solid get_color('neutral', 84);
        border-radius: 10px;
        padding: 0 3px;
        cursor: pointer;
        transition: opacity 0.25s;

        &:hover {
          background-color: get_color('primary', 88, 0.5);
        }

        &:active {
          color: get_color('neutral', 100);
          border-color: get_color('primary', 30);
          background-color: get_color('primary', 30);
        }
      }
      .desk {
        white-space: nowrap;
        max-width: 270px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .count {
        text-align: right;
      }
    }
  }
}
