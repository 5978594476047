@import '~/client/src/shared/theme.module';

$slack-bar-input-height: 36px;

.slack-bar {
  background-color: get_color('neutral', 100);
  border-top: solid 1px get_color('neutral', 96);

  * {
    outline: none;
  }

  &-input {
    border: none;
  }

  &-btns {
    display: flex;
    flex-direction: row;
  }

  &-btns button:not(.slack-bar-send-btn) {
    margin-right: 20px;
    color: get_color('neutral', 100);
    border: none;
    background: none;
    padding: 0;
  }

  &-send-btn {
    padding: 6px 10px;
    border: none;
    background-color: transparent;
    color: get_color('primary', 30);
    font-size: 16px;
    font-weight: 600;

    &.disabled {
      color: get_color('neutral', 50);
      opacity: 0.4;
    }
  }

  &-thread-type-list {
    margin: 0 -10px 10px;
  }

  &-thread-type {
    border-bottom: solid 2px get_color('grey', 88);
    cursor: pointer;

    .thread-type-first {
      padding-top: 0;

      &-svg {
        height: 18px;
      }
    }
  }

  &-thread-selected-type {
    font-weight: 600;
    font-size: 12px;
    color: get_color('primary', 20);
  }

  .mention-input,
  .mention-input__input {
    min-height: $slack-bar-input-height;
  }
}
