@import '~/client/src/shared/theme.module';

.ql-toolbar {
  border: none !important;
  height: 56px;
  background-color: get_color('neutral', 96);
  overflow-x: auto;

  & button {
    padding: 0 !important;
    width: unset !important;
    height: unset !important;

    i {
      font-style: normal;
      font-weight: 600;
    }

    .ql-active {
      background-color: get_color('neutral', 84) !important;
    }
  }

  .camera-icon svg {
    width: 24px;
    height: 24px;
  }

  .ql-bold text {
    fill: get_color('primary', 0, 0.38);
  }

  .attachment-upload-button {
    width: 24px;
    height: 24px;

    svg path {
      fill: get_color('primary', 0, 0.38);
    }
  }

  .ql-div {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .ql-formats {
    display: none;
  }

  .ql-picker-options {
    top: unset !important;
    bottom: 100%;
  }

  .ql-picker.ql-header {
    width: 24px !important;
    background: url('/static/icons/header.svg') no-repeat;
  }

  .ql-custom-header {
    display: flex !important;
    align-items: center;
  }

  .ql-picker .ql-picker-label {
    border: none;

    &::before {
      display: none !important;
    }

    svg {
      display: none;
    }
  }

  .ql-video svg {
    width: 20px;
  }
}
