@import '~/client/src/shared/theme.module';
@import '~/client/src/shared/mixins';

$icon-size: 20px;

.compact-user-profile-container {
  background-color: get_color('neutral', 100);

  .page-header-container {
    position: absolute;
    width: 100%;
    z-index: 100;
  }

  .wrapper {
    margin-top: 48px;
    padding-left: 27px;
    padding-right: 27px;
  }

  .user-actions {
    height: 35.19px;
    border: 1px solid get_color('primary', 40);
    border-radius: 4px;

    .icon {
      width: $icon-size;
      height: $icon-size;
      svg {
        width: $icon-size;
        height: $icon-size;
        path {
          fill: get_color('primary', 40);
        }
      }
    }

    .item-name {
      margin-left: 6px;
      color: get_color('primary', 40);
    }
  }

  .user-actions:first-child {
    margin-right: 20px;
  }

  .save-to-pdf-button {
    position: absolute;
    top: 10px;
    right: -20px;
    svg {
      width: 70px;
      height: 50px;
      path,
      line,
      polygon {
        fill: get_color('primary', 40);
        stroke: get_color('primary', 40);
      }
      polyline {
        stroke: get_color('primary', 40);
      }
    }
  }

  .user-actions:last-child {
    margin-left: 20px;
  }

  .user-project-items {
    margin-top: 20px;

    &.edit {
      margin-top: 17px;
    }
  }

  .avatar-loader-container {
    width: 172px;
    height: 172px;
  }

  .avatar-input-container {
    width: 172px;
    height: 172px;

    .avatar-container {
      height: 172px;
      width: 172px;

      .initials {
        font-size: 86px;
      }
    }

    input {
      display: none;
    }

    .bp3-file-input {
      height: 172px;
    }

    .bp3-file-upload-input {
      width: 172px;
      height: 172px;
      padding: 0; // to override default padding on AvatarInput
    }
  }
}
