@import '~/client/src/shared/theme.module';

.compact-announcement {
  width: 400px;
  max-height: 600px;
  .bp3-icon-chevron-left svg {
    width: 16px;
    height: 16px;
    path {
      fill: get_color('neutral', 0);
    }
  }

  &-holder {
    box-shadow: -4px 5px 6px -2px get_color('neutral', 0);

    .related-permit {
      cursor: pointer;
    }
  }

  &-header {
    background-color: get_color('neutral', 96);
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid get_color('grey', 88);
    justify-content: space-between;

    i[class$='-icon'] {
      display: inline-flex;
      svg path {
        color: get_color('primary', 100, 0.38);
      }
    }
    .header-icon svg {
      width: 24px;
      height: 24px;
    }
    .navigation-wrapper {
      display: flex;
      align-items: center;
    }
    .navigation-icon svg {
      width: 20px;
      height: 20px;
    }
    .navigation-title {
      font-size: 12px;
    }
  }

  .title-row {
    align-items: flex-start;

    .announcement-icon {
      svg {
        transform: scale(1.2);

        g path {
          fill: get_color('neutral', 100);
        }
      }
    }

    .title-text {
      max-width: unset;
      font-size: 25px;
    }
  }

  .collapsed {
    max-height: 180px;
  }

  img {
    max-width: 100%;
    height: unset !important;
  }

  .location-row-chain {
    color: unset;
    font-size: 14px;
  }
}
