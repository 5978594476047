@import '~/client/src/shared/theme.module';

.reports-header {
  .download-dropdown {
    left: initial;
    right: 0;
    top: 31px;
    min-width: 140px;
  }

  .download-button {
    width: 140px;
  }

  .bp3-spinner {
    .bp3-spinner-track {
      stroke: rgb(255 255 255 / 20%);
    }

    .bp3-spinner-head {
      stroke: rgb(255 255 255 / 80%);
    }
  }

  .bp3-progress-bar {
    height: 3px;
    margin-bottom: 1px;
    width: calc(100% - 5px);
  }

  .reports-header-btn {
    height: 32px;
    background-color: get_color('primary', 30);
    border-radius: 4px;
    color: get_color('neutral', 100);
    cursor: pointer;

    &-outline {
      background-color: get_color('neutral', 100) !important;
      border: solid 1px get_color('neutral', 84);
      color: get_color('neutral', 50) !important;
    }
  }
}
