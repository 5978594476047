@import '~/client/src/shared/theme.module';

$status-update-input-height: 50px;
$on-time-status-icon-size: 12px;

.status-update-input {
  border-top: 1px solid get_color('neutral', 70);
  position: relative;
  height: $status-update-input-height;
  overflow: hidden;

  &.opened {
    .status-update-input-close-button {
      left: 0;
    }

    .status-update-input-apply-button {
      right: 0;
    }
  }

  .status-update-input-triangle {
    display: inline-block;
    width: 6px;
    height: 5px;
    border-top: 5px solid get_color('neutral', 70);
    border-right: 3px solid get_color('neutral', 100);
    border-left: 3px solid get_color('neutral', 100);
    position: absolute;
    top: 0;
    left: calc(50% - 3px);

    &.gold {
      border-top: 5px solid get_color('secondary', 40);
    }
  }

  &-target-value {
    font-size: 16px;
    color: get_color('secondary', 40);
  }

  &-target-dot {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: get_color('secondary', 40);
  }

  &-close-button,
  &-apply-button {
    width: $status-update-input-height;
    height: $status-update-input-height;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-grow: unset;
    position: absolute;
    top: 0;
    z-index: 5;
    -webkit-transition: left 0.3s, right 0.3s;
    -moz-transition: left 0.3s, right 0.3s;
    -o-transition: left 0.3s, right 0.3s;
    transition: left 0.3s, right 0.3s;
  }

  &-values-container {
    background-color: get_color('neutral', 96);
    height: $status-update-input-height;
  }

  &-scroll-container {
    height: $status-update-input-height;
    white-space: nowrap;
    position: relative;
    z-index: 1;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  &-value {
    display: inline-block;
    width: 40px;
    line-height: $status-update-input-height;
    text-align: center;
    color: get_color('neutral', 50);
    position: relative;
    font-size: 16px;

    &.target {
      color: get_color('secondary', 40);
    }

    &.displayed {
      position: absolute;
      left: calc(50% - 20px);
      top: 0;
    }

    &.selected {
      color: get_color('primary', 30);
      font-size: 20px;
      font-weight: 600;

      &.text-node {
        font-size: 16px;
      }
    }

    .status-update-input-target-dot {
      position: absolute;
      bottom: 8px;
      left: calc(50% - 3px);
    }
  }

  &-close-button {
    background-color: get_color('neutral', 70);
    left: -#{$status-update-input-height};

    path {
      fill: get_color('neutral', 50);
    }
  }

  &-apply-button {
    background-color: get_color('primary', 30);
    font-size: 14px;
    color: get_color('neutral', 100);
    font-weight: 600;
    right: -#{$status-update-input-height};

    .actual-button {
      background-color: get_color('primary', 70);
      color: get_color('neutral', 100);
      font-weight: 600;
      justify-content: center;
      align-items: center;
      display: flex;
      width: 100%;
      height: 100%;
    }

    path {
      fill: get_color('neutral', 100);
    }

    .bp3-spinner-track {
      stroke: transparent;
    }

    .bp3-spinner-head {
      stroke: get_color('neutral', 100);
    }
  }
}

.actual-date.text {
  color: get_color('primary', 70);
}

.unread-activity {
  text-shadow: 1px 0 0 get_color('neutral', 0);
}

.indicators-section {
  i {
    flex-grow: 0;
  }
}

.activity-status-cell {
  @mixin status($color) {
    border: 1px solid get_color($color, 84) !important;
    background-color: get_color($color, 92) !important;
    color: get_color($color, 40) !important;
  }

  border: 1px solid get_color('primary', 40);
  background-color: get_color('neutral', 100);
  color: get_color('primary', 40);

  &.in-progress {
    border: 1px solid get_color('warning', 40) !important;
    background-color: get_color('warning', 92) !important;
    color: get_color('neutral', 0) !important;
  }
  &.done,
  &.starts-today {
    border: 1px solid get_color('neutral', 84) !important;
    background-color: get_color('neutral', 80) !important;
    color: get_color('neutral', 0) !important;
  }
  &.completed {
    @include status('primary');
  }
  &.due-tomorrow {
    @include status('secondary');
  }
  &.due-today-or-late {
    @include status('error');
  }

  .on-time-status-icon {
    width: $on-time-status-icon-size;
    height: $on-time-status-icon-size;
    bottom: 15px;
    right: -8px;
    z-index: 1;
    position: absolute;
  }
}

.activity-location-tags {
  display: flex;
  justify-content: start;
  overflow: hidden;
  .workflow-card-location-row {
    margin-right: 10px;
    flex-shrink: 0;
  }
}

.fading-animation {
  -webkit-animation-name: fading;
  -webkit-animation-duration: 0.5s;
  animation-name: fading;
  animation-duration: 0.5s;
}

@-webkit-keyframes fading {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.2;
  }
  40% {
    opacity: 0.4;
  }
  60% {
    opacity: 0.6;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fading {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.2;
  }
  40% {
    opacity: 0.4;
  }
  60% {
    opacity: 0.6;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
