$lightModeTonesMap: (
  0: 0,
  10: 12,
  20: 24,
  30: 35,
  40: 47,
  50: 55,
  60: 63,
  70: 71,
  80: 80,
  84: 84,
  88: 88,
  90: 90,
  92: 92,
  96: 96,
  100: 100,
);

$darkModeTonesMap: (
  0: 100,
  10: 90,
  20: 88,
  30: 80,
  40: 70,
  50: 60,
  60: 50,
  70: 50,
  80: 50,
  84: 50,
  88: 40,
  90: 40,
  92: 30,
  96: 20,
  100: 10,
);

@function create_tone_map($lightL, $toneCode) {
  $darkL: map-get($darkModeTonesMap, $toneCode);

  $map: (
    lightTheme: $lightL,
    darkTheme: $darkL,
  );
  @return $map;
}

$tones-map: ();

@each $toneCode, $lValue in $lightModeTonesMap {
  $t-map: ();
  $t-map: map-merge($t-map, (#{$toneCode}: create_tone_map($lValue, $toneCode)) );
  $tones-map: map-merge($tones-map, $t-map);
}

@mixin styles($mode) {
  @each $key, $map in $tones-map {
    @each $prop, $color in $map {
      @if $prop == $mode {
        --tone#{$key}: #{$color+'%'};
      }
    }
  }
}

.dark-mode {
  @include styles("darkTheme");
}

.light-mode {
  @include styles("lightTheme");
}
