.photo-activity-caption {
  display: flex;
  @mixin ellipsis-item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  position: relative;
  margin: 10px 0;

  * {
    vertical-align: middle;
    display: inline-block;
    font-size: 14px;
  }

  .photo-activity-name {
    @include ellipsis-item;
    flex: 70;
  }

  .photo-activity-id {
    @include ellipsis-item;
    flex: 30;
  }

  .separator {
    padding: 0 10px;
  }

  .photo-activity-right-side-content {
    @include ellipsis-item;

    position: absolute;
    right: 0;
    bottom: 0;
    width: 100px;
    text-align: right;
    font-size: 11px;
  }
}
