@import '~/client/src/shared/theme.module';

$default-icon-size: 16px;

article.permit-card {
  width: 100%;
  .date-status-row {
    .association-icon {
      i,
      i svg {
        height: 20px;
        width: 20px;
      }
    }
  }

  .permit-type-icon {
    width: 24px;
    height: 24px;
  }

  .company-mw150 {
    max-width: 150px;
  }
}
