@import '~/client/src/shared/theme.module';

.compact-confirm-dialog {
  border-radius: 6px;
  padding: 0;

  .modal-header {
    padding: 0 0 0 20px;
  }

  .blue-text {
    color: get_color('primary', 70);
  }

  .cancel-button,
  .apply-button {
    border: 1px solid get_color('error', 50);
    border-radius: 6px;
  }

  .cancel-button {
    background-color: get_color('error', 50);
    color: get_color('neutral', 100);
    width: 80px;
  }

  .apply-button {
    color: get_color('error', 50);
    width: 180px;
  }

  &.blue-theme {
    .cancel-button {
      border: 1px solid get_color('primary', 30);
      background-color: get_color('primary', 30);
    }

    .apply-button {
      border: 1px solid get_color('primary', 30);
      color: get_color('primary', 30);
    }
  }
}
