@import '~/client/src/shared/theme.module';

.color-palette-container {
  display: inline-block;
  vertical-align: middle;
  box-shadow: 0px 2px 4px get_color('neutral', 0, 0.15);
  border: 1px solid get_color('neutral', 80);
  border-radius: 4px;
  background-color: get_color('neutral', 100);
  padding: 2px 11px 0 11px;

  .chrome-picker {
    box-shadow: none !important;
  }
}
