@import '~/client/src/shared/theme.module';

.sidebar-footer {
  .container {
    padding: 20px 16px 20px 20px;
    display: flex;
    align-items: center;

    .build-info {
      text-align: end;
    }
  }
}

@media screen and (max-width: 330px) {
  .sidebar-footer {
    .container {
      display: block;

      .logo {
        margin-bottom: 12px;
      }

      .build-info {
        text-align: start;
      }
    }
  }
}
