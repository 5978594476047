@import '~/client/src/shared/theme.module';

.daily-mobile-component {
  .activities-list-filter {
    min-height: 55px;
    max-height: 55px;
    background-color: get_color('neutral', 96);

    .filter-btn {
      height: 30px;
      font-size: 12px;

      color: get_color('neutral', 50);
      border: solid 1px get_color('neutral', 84);
      outline: none;
      background-color: get_color('neutral', 100);

      &.left {
        border-radius: 5px 0 0 5px;
        border-right: none;
      }

      &.right {
        border-radius: 0 5px 5px 0;
        border-left: none;
      }

      &.custom {
        border-radius: 5px;
      }

      &.active {
        background-color: get_color('primary', 30);
        color: get_color('neutral', 100);
      }
    }

    .filter-separator {
      min-width: 1px;
      height: 20px;
      border: none;
      background-color: get_color('grey', 84);
    }

    .filter-icon {
      height: 20px;
    }
  }
}
