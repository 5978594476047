@import '~/client/src/shared/theme.module';

$arrow-icon-size: 14px !important;

.sitemaps-gallery {
  .items-holder {
    z-index: 2;
    top: 10px;
    left: 10px;
    &.top-offset {
      top: 85px !important;
    }
    &.left-offset {
      left: 0 !important;
    }
    .map-icon {
      box-shadow: 0px 2px 2px get_color('neutral', 0, 0.25),
        0px 3px 1px get_color('neutral', 0, 0.25),
        0px 1px 5px get_color('neutral', 0, 0.25);
      min-width: 35px;
      height: 35px;
      i {
        width: 18px;
        height: 18px;
      }
    }
    .weather-block {
      color: get_color('neutral', 60);
      border-radius: 4px;
      height: 35px;
      box-shadow: 0px 2px 2px get_color('neutral', 0, 0.25),
        0px 3px 1px get_color('neutral', 0, 0.25),
        0px 1px 5px get_color('neutral', 0, 0.25);
      .weather-label {
        flex-direction: row-reverse !important;
      }
    }
  }
  .current-map-name {
    &.top-offset {
      top: 45px !important;
    }

    border: 1px solid get_color('neutral', 92);
    box-shadow: 0px 2px 2px get_color('neutral', 0, 0.25),
      0px 3px 1px get_color('neutral', 0, 0.25),
      0px 1px 5px get_color('neutral', 0, 0.25);
    border-radius: 8px;
    top: 8px !important;
    z-index: 12;
    left: 12px;
    height: 29px;

    span {
      width: 97px;
      max-width: 97px;
    }

    i {
      display: inline-flex;
      svg {
        width: 13px;
        height: 13px;
      }
    }
  }

  .arrows-holder {
    max-width: 68px;
    &.top-offset {
      top: 45px !important;
    }

    border: 1px solid get_color('neutral', 92);
    height: 29px;
    box-shadow: 0px 2px 2px get_color('neutral', 0, 0.25),
      0px 3px 1px get_color('neutral', 0, 0.25),
      0px 1px 5px get_color('neutral', 0, 0.25);
    border-radius: 18px;
    height: 36px;
    right: 12px !important;
    top: 8px !important;
    z-index: 12;
    &.left {
      left: 195px !important;
      right: unset !important;
    }

    .sitemap-counts {
      width: 28px;
    }

    .bp3-icon.bp3-icon-chevron-left svg {
      width: $arrow-icon-size;
      height: $arrow-icon-size;

      path {
        fill: get_color('primary', 0);
      }
    }

    .bp3-icon.bp3-icon-chevron-right svg {
      width: $arrow-icon-size;
      height: $arrow-icon-size;

      path {
        fill: get_color('primary', 0);
      }
    }
  }
}

.logistics-creation-options-container {
  top: 0;

  .create-new-button-container {
    top: 10px;
    right: 10px;
    z-index: 12;

    &.calendar-view,
    &.list-view {
      position: relative !important;
      top: unset !important;
      right: unset !important;
    }
  }

  .create-options-container {
    top: 50px;
    right: 10px;
    z-index: 12;

    .creation-modal-popup {
      max-height: 300px;
      max-width: 450px;
    }
  }

  .create-option:hover {
    background-color: get_color('neutral', 96);
    cursor: pointer;
  }

  .create-new-button {
    background-color: get_color('primary', 30) !important;
    border-color: get_color('primary', 30) !important;
    color: get_color('neutral', 100) !important;

    svg path:first-child {
      fill: none;
      stroke: none;
    }

    svg path:last-child {
      stroke: get_color('neutral', 100);
      fill: get_color('neutral', 100);
    }

    &.active,
    &:hover {
      background-color: get_color('neutral', 100) !important;
      color: get_color('violet', 40) !important;

      svg path:last-child {
        stroke: get_color('violet', 40);
        fill: get_color('violet', 40);
      }
    }
  }
}
