@import '~/client/src/shared/theme.module';

$cell-size: 48px;

%planned-range-line {
  content: '';
  display: inline-block;
  height: 1px;
  width: 100px;
  background-color: get_color('neutral', 0);
  position: absolute;
  bottom: 3px;
}

.bulk-status-update {
  .actual-dates-change-dialog {
    border-radius: 6px;
    padding: 0;

    .modal-header {
      padding: 0 0 0 20px;
    }

    .blue-text {
      color: get_color('primary', 70);
    }

    .cancel-button,
    .apply-button {
      border: 1px solid get_color('error', 50);
      border-radius: 6px;
    }

    .cancel-button {
      background-color: get_color('error', 50);
      color: get_color('neutral', 100);
      width: 80px;
    }

    .apply-button {
      color: get_color('error', 50);
      width: 180px;
    }
  }

  .scroll-area {
    overflow-y: auto;
    height: 100%;
  }

  &-calendar {
    &-companies {
      transition: all 400ms;
      height: 40px;
      &-tab {
        &.single-company {
          cursor: pointer;
          flex-shrink: 0;
          flex-basis: auto;
          min-width: 90px;
          padding: 0 16px 0 16px;
          white-space: nowrap;
        }
        &.active {
          transition: all 600ms;
          border-bottom-width: 2px;
        }
        &.selected {
          position: absolute;
          top: 0;
          transition: all 400ms;
        }
      }
    }

    &-legend {
      svg {
        width: 12px;
        height: 12px;
      }

      .actual-dates-icon {
        width: 10px;
        height: 10px;
        background-color: get_color('primary', 70);
      }

      .planned-range-icon {
        display: inline-block;
        width: 26px;
        height: 1px;
        background-color: get_color('neutral', 0);
        position: absolute;
        bottom: 4px;
        left: 0;

        &::before,
        &::after {
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: get_color('neutral', 0);
          position: absolute;
          bottom: -4px;
        }

        &::before {
          left: 0;
        }

        &::after {
          right: 0;
        }

        &-container {
          flex-basis: 26px;
          height: 8px;
        }
      }
    }
    .calendars-scroller {
      height: 340px;
      .calendar-holder {
        position: absolute;
        top: 0;
        transition: left 400ms;
        .infinity-calendar {
          .month-label {
            padding-left: 50px;
          }

          .dp-container {
            padding: 0 50px;
            display: block;
            padding: 0 10px;

            .p-datepicker {
              .date-cell {
                width: $cell-size;
                height: $cell-size !important;
                padding: 0;
                border-top: 1px solid get_color('neutral', 84);
                border-bottom: 1px solid get_color('neutral', 84);
                border-left: none;
                border-right: none;
                position: relative;
                cursor: pointer;

                &.p-today:after {
                  content: '';
                  display: inline-block;
                  height: 8px;
                  width: 8px;
                  border-radius: 4px;
                  background-color: get_color('secondary', 40);
                  position: absolute;
                  bottom: 4px;
                  left: calc(50% - 4px);
                }

                &.selected-date {
                  background-color: get_color('primary', 88);
                  color: get_color('neutral', 0);
                  border: 1px solid get_color('primary', 30);
                }

                &.missing {
                  color: get_color('error', 50);
                  font-weight: 600;
                }

                &.updated {
                  color: get_color('primary', 30);
                  font-weight: 600;
                }

                &.actual-date {
                  background-color: get_color('primary', 70);
                  color: get_color('neutral', 100);
                }

                .day-status-icon {
                  position: absolute;
                  right: 2px;
                  top: 2px;

                  svg {
                    width: 12px;
                    height: 12px;
                  }
                }

                .planned-range {
                  position: absolute;
                  bottom: 4px;

                  &:not(.planned-range-start)::before {
                    @extend %planned-range-line;
                    right: 0;
                  }

                  &:not(.planned-range-end)::after {
                    @extend %planned-range-line;
                    left: 0;
                  }

                  &.planned-range-end,
                  &.planned-range-start {
                    display: inline-block;
                    height: 8px;
                    width: 8px;
                    border-radius: 4px;
                    background-color: get_color('neutral', 0);
                    position: absolute;
                    left: calc(50% - 4px);
                    z-index: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  $status-update-input-height: 50px;

  .actual-date-button {
    color: get_color('primary', 70);
    border: 1px solid get_color('primary', 70);
    width: 80px;
    border-radius: 10px;
    padding: 3px 0;

    &.disabled {
      border: 1px solid get_color('neutral', 84);
      color: get_color('neutral', 50);
    }

    &.active {
      background-color: get_color('primary', 70);
      color: get_color('neutral', 100);
    }
  }

  .status-update-input {
    border-top: 1px solid get_color('neutral', 70);
    position: relative;
    height: $status-update-input-height;
    overflow: hidden;

    &.opened {
      .status-update-input-close-button {
        left: 0;
      }

      .status-update-input-apply-button {
        right: 0;
      }
    }

    .status-update-input-triangle {
      display: inline-block;
      width: 6px;
      height: 5px;
      border-top: 5px solid get_color('neutral', 70);
      border-right: 3px solid get_color('neutral', 100);
      border-left: 3px solid get_color('neutral', 100);
      position: absolute;
      top: 0;
      left: calc(50% - 3px);

      &.gold {
        border-top: 5px solid get_color('secondary', 40);
      }
    }

    &-target-value {
      font-size: 16px;
      color: get_color('secondary', 40);
    }

    &-target-dot {
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: get_color('secondary', 40);
    }

    &-close-button,
    &-apply-button {
      width: $status-update-input-height;
      height: $status-update-input-height;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-grow: unset;
      position: absolute;
      top: 0;
      z-index: 5;
      -webkit-transition: left 0.3s, right 0.3s;
      -moz-transition: left 0.3s, right 0.3s;
      -o-transition: left 0.3s, right 0.3s;
      transition: left 0.3s, right 0.3s;
    }

    &-values-container {
      background-color: get_color('neutral', 96);
      height: $status-update-input-height;
    }

    &-scroll-container {
      height: $status-update-input-height;
      white-space: nowrap;
      position: relative;
      z-index: 1;
      overflow-x: scroll;
      overflow-y: hidden;
    }

    &-value {
      display: inline-block;
      width: 40px;
      line-height: $status-update-input-height;
      text-align: center;
      color: get_color('neutral', 50);
      position: relative;
      font-size: 16px;

      &.target {
        color: get_color('secondary', 40);
      }

      &.displayed {
        position: absolute;
        left: calc(50% - 20px);
        top: 0;
      }

      &.selected {
        color: get_color('primary', 30);
        font-size: 20px;
        font-weight: 600;
      }

      .status-update-input-target-dot {
        position: absolute;
        bottom: 8px;
        left: calc(50% - 3px);
      }
    }

    &-close-button {
      background-color: get_color('neutral', 70);
      left: -#{$status-update-input-height};

      path {
        fill: get_color('neutral', 50);
      }
    }

    &-apply-button {
      background-color: get_color('primary', 30);
      font-size: 14px;
      color: get_color('neutral', 100);
      font-weight: 600;
      right: -#{$status-update-input-height};

      path {
        fill: get_color('neutral', 100);
      }

      .bp3-spinner-track {
        stroke: transparent;
      }

      .bp3-spinner-head {
        stroke: get_color('neutral', 100);
      }
    }
  }

  .fading-animation {
    -webkit-animation-name: fading;
    -webkit-animation-duration: 0.5s;
    animation-name: fading;
    animation-duration: 0.5s;
  }

  @-webkit-keyframes fading {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0.2;
    }
    40% {
      opacity: 0.4;
    }
    60% {
      opacity: 0.6;
    }
    80% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fading {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0.2;
    }
    40% {
      opacity: 0.4;
    }
    60% {
      opacity: 0.6;
    }
    80% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }

  .done-button {
    background-color: get_color('primary', 30);
  }
}
