@import '~/client/src/shared/theme.module';

.file {
  &-preview {
    width: 150px;
    height: 100px;
    flex-shrink: 0;
    flex-grow: 0;
    object-fit: contain;
    overflow: hidden;
    display: inline-block;
    position: relative;
    &.small {
      height: 75px;
      width: 120px;
    }
    &.extra-small {
      height: 20px;
      width: 29px;
    }
    &-icon {
      position: absolute;
      bottom: -1px;
      left: -1px;
      background: get_color('neutral', 100);
      display: flex;
      align-items: center;
      justify-content: center;
      &-pdf {
        height: 35px;
        width: 55px;
      }
      &-image {
        height: 25px;
        width: 30px;
      }
    }
    &.flexible {
      width: 100%;
      height: initial;
    }
  }
  &-button {
    position: relative;
    display: inline-block;
    &-hidden-input {
      opacity: 0;
      z-index: -10;
    }
    &-input {
      border: none;
      background: none;
    }
  }
}
