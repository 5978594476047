@import '~/client/src/shared/theme.module';

.delivery-field-icon,
.delivery-field-icon svg {
  height: $DEFAULT_ICON_SIZE !important;
  width: $DEFAULT_ICON_SIZE !important;

  &.with-color {
    fill: get_color('neutral', 30) !important;

    path {
      fill: get_color('neutral', 30) !important;
    }
  }

  &.gate {
    color: get_color('neutral', 30) !important;
  }
}
