@import '~/client/src/shared/theme.module';

.location-picker {
  .cross-icon {
    display: inline-flex;

    svg {
      width: 26px;
      height: 26px;

      path:last-child {
        fill: get_color('primary', 50);
      }
    }
  }

  &-submit {
    background-color: get_color('primary', 50);
    color: get_color('neutral', 100);
  }

  .view-selector {
    button {
      display: inline-flex;
      vertical-align: top;
      flex-direction: row;
      font-size: 14px;
      font-weight: 600;
      align-items: center;
      color: get_color('neutral', 50);
      border: 1px solid get_color('neutral', 50);
      background-color: get_color('neutral', 100);

      &.active {
        background-color: get_color('primary', 96);
        border: 1px solid get_color('primary', 50);
        color: get_color('primary', 50);

        svg {
          color: get_color('primary', 50);

          path,
          polygon {
            fill: get_color('primary', 50);
          }
        }
      }

      svg {
        width: 20px;
        height: 20px;
        color: get_color('neutral', 50);

        path,
        polygon {
          fill: get_color('neutral', 50);
        }
      }
    }
  }

  .checkboxes-container {
    .p-checkbox-box {
      background: get_color('neutral', 100) !important;
      border-color: get_color('neutral', 50) !important;
      color: get_color('neutral', 50) !important;
    }

    .checked {
      .p-checkbox-box {
        background: get_color('primary', 50) !important;
        border-color: get_color('primary', 50) !important;
        color: get_color('neutral', 100) !important;
      }
    }

    .bp3-icon-chevron-right,
    .bp3-icon-chevron-left {
      color: get_color('neutral', 50);

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  @keyframes rotateIcon {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(-240deg);
      transform: rotate(-240deg);
    }
    100% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }

  .reset-btn > span.bp3-icon-reset {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    user-select: none;
  }
  .reset-btn:focus > span.bp3-icon-reset {
    -webkit-animation: rotateIcon 0.7s ease-in-out;
    animation: rotateIcon 0.7s ease-in-out;
  }
  .reset-btn:active > span.bp3-icon-reset {
    -webkit-animation: none;
    animation: none;
  }
}
