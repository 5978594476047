@import '~/client/src/shared/theme.module';

$icon-size: 16px;

@mixin theme-template($main-color, $secondary-color, $border-color) {
  color: $main-color;
  border-color: $border-color;
  background-color: $secondary-color;

  svg {
    fill: $main-color;
    color: $main-color;
  }

  @media (hover: hover) {
    &:hover,
    &.active {
      color: $secondary-color;
      background-color: $main-color;

      svg {
        fill: $secondary-color;
        color: $secondary-color;
      }
    }
  }

  @media (hover: none) {
    &:active,
    &.active {
      color: $secondary-color;
      background-color: $main-color;

      svg {
        fill: $secondary-color;
        color: $secondary-color;
      }
    }
  }
}

button.base-action-btn {
  height: 32px;
  background-color: get_color('neutral', 100);

  &.animated {
    transition: background-color 0.4s ease;
  }

  &.fixed-icon-size {
    svg,
    i {
      height: $icon-size !important;
      width: $icon-size !important;
    }
  }

  &.primary-theme {
    @include theme-template(
      get_color('primary', 30),
      get_color('neutral', 100),
      get_color('primary', 30)
    );
  }

  &.primary-theme-inverted {
    @include theme-template(
      get_color('neutral', 100),
      get_color('primary', 30),
      get_color('primary', 30)
    );
  }

  &.gray-theme {
    @include theme-template(
      get_color('neutral', 60),
      get_color('neutral', 100),
      get_color('neutral', 60)
    );
  }

  &.secondary-blue-theme {
    @include theme-template(
      get_color('primary', 70),
      get_color('neutral', 100),
      get_color('primary', 70)
    );
  }

  &.scale-blue-theme {
    @include theme-template(
      get_color('neutral', 100),
      get_color('primary', 50),
      get_color('primary', 50)
    );
  }

  &.inverse-scale-blue-theme {
    @include theme-template(
      get_color('primary', 50),
      get_color('neutral', 100),
      get_color('primary', 50)
    );
  }

  &.red-theme {
    @include theme-template(
      get_color('error', 50),
      get_color('neutral', 100),
      get_color('error', 50)
    );
  }

  &.green-theme {
    @include theme-template(
      get_color('success', 40),
      get_color('neutral', 100),
      get_color('success', 40)
    );
  }
}
