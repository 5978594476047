@import '~/client/src/shared/theme.module';

.white-status-update {
  circle {
    stroke: get_color('neutral', 100);
  }

  path {
    fill: get_color('neutral', 100);
  }
}
