@import '~/client/src/shared/theme.module';

$scrollbar-height: 6px;

.weather-hourly-widget {
  display: flex;
  overflow: auto;
  margin-bottom: $scrollbar-height;
  user-select: none;

  // TODO: Change on normal scroll
  &::-webkit-scrollbar {
    height: $scrollbar-height;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset transperent;
  }

  &::-webkit-scrollbar-thumb {
    background: get_color('neutral', 50);
    border-radius: 10px;
    &:hover {
      background: get_color('neutral', 30);
    }
  }

  .weather-bar {
    min-height: 124px;
    max-height: 160px !important;
    background-color: get_color('neutral', 100);

    .icon {
      width: 24px !important;
      height: 24px !important;
      svg {
        width: inherit;
        height: inherit;
      }
    }
  }
}
