@import '~/client/src/shared/theme.module';

.avatar-input-container {
  &.compact-avatar {
    position: relative;

    .bp3-file-upload-input {
      border-radius: 50%;
    }

    input {
      display: none;
    }
    .remove-icon {
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 1;
      background-color: get_color('neutral', 100);
      padding: 2px;
      border-radius: 50%;
    }
  }

  .remove-icon {
    color: get_color('neutral', 60);
  }

  .avatar-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 33%;
    text-align: center;
    background-color: get_color('neutral', 100, 0.25);
    color: get_color('neutral', 60);
  }

  .avatar-container svg {
    fill: get_color('neutral', 50);
  }

  .bp3-file-input {
    input {
      width: 100%;
      min-width: unset;
    }

    .bp3-file-upload-input {
      padding: 0 10px;
    }
  }

  .avatar-edit-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 105;
    background: get_color('primary', 0, 0.2);

    &-popup {
      position: absolute;
      width: 90%;
      height: fit-content;
      bottom: 0;
      top: 0;
      right: 0;
      left: 0;
      @media screen and (min-width: $SMALL-SCREEN-WIDTH) {
        max-width: 50vw;

        .canvas-container {
          max-width: 80vw;
          max-height: 80vh;
          overflow: auto;
        }
      }

      canvas {
        width: 100%;
        aspect-ratio: 1;
        touch-action: none;
      }

      input {
        display: block !important;
      }
    }
  }
}
