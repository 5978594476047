@import '~/client/src/shared/theme.module';

@mixin commonStatusParams($color-name) {
  border: 1px solid get_color($color-name, 84) !important;
  background: get_color($color-name, 92) !important;
  color: get_color($color-name, 40) !important;
}

@mixin cancelledStatusParams() {
  border: 1px solid get_color('neutral', 88) !important;
  background: get_color('neutral', 96) !important;
  color: get_color('neutral', 10) !important;
}

.concrete-direct-order-status {
  &-requested,
  &-unconfirmed {
    border: 1px solid get_color('primary', 40) !important;
    background: get_color('primary', 100) !important;
    color: get_color('primary', 40) !important;
  }
  &-cancelled,
  &-completed {
    @include cancelledStatusParams();
  }
  &-confirmed {
    @include commonStatusParams('primary');
  }
  &-delivering {
    @include commonStatusParams('secondary');
  }
  &-paused {
    @include commonStatusParams('error');
  }
  &-on_hold {
    border: 1px solid get_color('warning', 40) !important;
    background: get_color('warning', 92) !important;
    color: get_color('neutral', 10) !important;
  }
}

.concrete-direct-payload-status {
  &-cancelled,
  &-delivered,
  &-recalled {
    @include cancelledStatusParams();
  }
  &-ticketed,
  &-loading,
  &-scheduled {
    @include commonStatusParams('primary');
  }
  &-pouring,
  &-on_site,
  &-en_route {
    @include commonStatusParams('secondary');
  }
}
