@import '~/client/src/shared/theme.module';

.save-password {
  position: fixed;
  height: 100%;
  left: 0;
  right: 0;
  z-index: 120;
  display: flex;
  background-color: get_color('primary', 30, 0.5);

  &-popup {
    display: flex;
    flex-direction: column;
    width: 380px;
    background-color: get_color('neutral', 100);
    box-shadow: 0 2px 5px 0 get_color('neutral', 84);
    padding: 20px;
    margin: auto;
    position: relative;

    > div {
      flex: none !important;
    }

    .popup-title {
      font-weight: 600;
      font-size: 18px;
      color: get_color('neutral', 0);
    }

    .buttons {
      margin-top: 70px;
      button {
        border-radius: 2px;
        background-color: get_color('neutral', 100);
        color: get_color('primary', 30);
        border: 1px solid get_color('primary', 30);
        font-weight: 600;
        font-size: 14px;
        height: 28px;
        width: 74px;
        text-transform: capitalize;
      }
    }
  }
}
