@import '~/client/src/shared/theme.module';

@mixin arrow {
  position: absolute;
  top: 13px;
  z-index: 1;
  background-color: get_color('neutral', 100);
  opacity: 0.8;

  svg {
    fill: get_color('primary', 30);
  }
}

.nav.bp3-icon.bp3-icon-chevron-left {
  @include arrow;
  left: 681px;
}

.nav.bp3-icon.bp3-icon-chevron-right {
  @include arrow;
  right: 0;
}
