@import '~/client/src/shared/theme.module';

.deliveries {
  &-map-view {
    .sitemaps-gallery {
      z-index: 7;
      &.select-menu-opened {
        z-index: 9 !important;
      }
    }

    .deliveries-map-indication {
      z-index: 8;
      top: 8px;
      right: 12px;
      box-shadow: 0 1px 1px 0 get_color('primary', 0, 0.8),
        0 2px 1px -1px get_color('primary', 0, 0.8),
        0 1px 3px 0 get_color('primary', 0, 0.8);
    }
  }

  &-filters-header {
    height: 48px;
    color: get_color('neutral', 50);
    $icon-height: 18px;

    .deliveries-filters-header-inner-scroller {
      overflow-x: auto;
      overflow-y: hidden;
      .deliveries-filters-header-filter {
        height: 32px;
        align-items: center;
        .deliveries-filters-header-filter-icon {
          height: $icon-height;
          &.filters-icon svg {
            height: $icon-height;
            g {
              fill: get_color('neutral', 50);
            }
          }
        }
        .deliveries-filters-header-filter-icon svg {
          height: $icon-height;
        }
        &.active {
          border: 1px solid get_color('primary', 50);
          background-color: get_color('primary', 96);
          color: get_color('primary', 50);
          .deliveries-filters-header-filter-icon {
            svg g {
              fill: get_color('primary', 96);
              stroke: get_color('primary', 50);
            }

            &.company-icon {
              svg g {
                stroke: get_color('primary', 96);
              }
              svg rect {
                fill: get_color('primary', 50);
                stroke: get_color('primary', 50);
              }
            }
          }
        }
        .delivery-filter-count-label {
          border: 1px solid get_color('primary', 50);
          background-color: get_color('primary', 50);
          color: get_color('neutral', 100);
        }
      }
      .deliveries-filters-header-separator {
        height: 36px;
      }
    }
  }

  &-sub-header {
    max-height: 100px;
    .label {
      border-radius: 10px;
      margin: 0 10px;
      padding: 0 10px;
      display: inline-block;

      &.active {
        background-color: get_color('neutral', 92);
      }
    }

    .horizontal-scroll {
      width: 100%;
      height: 50px;
      white-space: nowrap;
      position: relative;
      overflow-x: scroll;
      overflow-y: hidden;
      padding: 15px 0;
    }
  }

  &-carousel {
    bottom: 5px;
    z-index: 8;

    .delivery-card-container {
      transition: transform 0.5s ease;

      .delivery-workflow-card {
        width: calc(100% - 48px);
      }
    }
  }

  &-add-button {
    background-color: get_color('neutral', 100);
    display: inline-block;
    position: absolute;
    border: 1px solid get_color('primary', 30);
    border-radius: 50%;
    padding: 9px;
    right: 15px;
    bottom: 15px;
    z-index: 2;
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    box-shadow: 0px 0px 34px 0px get_color('neutral', 0, 0.25);
  }

  &-weather-label-container {
    height: 39px;

    .weather-label > div {
      padding: 0 8px;
    }
  }
}
