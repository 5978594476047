@import '~/client/src/shared/theme.module';

.hidden-cursor {
  cursor: unset !important;
  div {
    cursor: unset !important;
  }
}

.mapbox-editor {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  .mapboxgl-control-container {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .mapboxgl-ctrl-group button {
    width: 35px;
    height: 35px;
    padding: 5px;
  }

  .mapboxgl-popup {
    z-index: 3;
    cursor: pointer;
  }
  .additional-project-marker {
    z-index: 3;
    svg {
      path:nth-child(1) {
        fill: get_color('error', 50);
      }
    }
  }

  .mapboxgl-ctrl-bottom-right {
    display: none;
  }

  .mapboxgl-marker {
    svg {
      width: 21px;
      height: 21px;
    }
    .globe-announcement-icon {
      width: 25px !important;
      height: 25px !important;
      svg {
        width: 25px !important;
        height: 25px !important;
      }
      circle {
        fill: none;
      }
    }
    .globe-permit-icon {
      width: 22px !important;
      height: 22px !important;
    }
    .globe-delivery-icon {
      svg {
        width: 12px;
        height: 12px;
        path {
          fill: inherit !important;
          stroke: inherit !important;
        }
      }
    }
    .globe-location {
      z-index: 1;

      &.marker {
        padding: 2px;
        border: 1px solid white;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        &.highlighted {
          width: 26px;
          height: 26px;
          border: 2px solid white;
        }
      }
      &:hover {
        z-index: 2;
        .globe-pin {
          width: 42px;
          height: 42px;
        }
      }
      &.selected-location {
        z-index: 2;
        .globe-pin {
          width: 45px !important;
          height: 45px !important;
        }
      }
      .location-icon {
        width: 14px;
        height: 14px;
        &.highlighted {
          width: 16px;
          height: 16px;
        }
      }
      .closed-location {
        width: 24px;
        height: 24px;
        &.highlighted {
          width: 28px;
          height: 28px;
        }
      }
      .globe-pin-image {
        position: absolute;
        width: 20px;
        height: 20px;
        margin-bottom: 11px;
        &.permit-white-icon {
          svg path {
            &:nth-child(1) {
              fill: get_color('neutral', 0);
            }
            &:nth-child(2) {
              fill: get_color('neutral', 100);
            }
          }
        }
        &.permit-black-icon {
          svg path {
            &:nth-child(1) {
              fill: get_color('neutral', 100);
            }
            &:nth-child(2) {
              fill: get_color('neutral', 0);
            }
          }
        }
      }
      .globe-pin {
        width: 40px;
        height: 40px;
      }
    }
  }

  .mapbox-editor__map {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  .mapboxgl-ctrl-bottom-left {
    display: none;
  }

  .map-control-container {
    z-index: 100;
    bottom: 0;
    left: 0;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 20px;
  }

  &.hidden-controls {
    .mapboxgl-ctrl-bottom-left {
      display: none;
    }
    .mapboxgl-ctrl-bottom-right {
      display: none;
    }
  }
}
