@import '~/client/src/shared/theme.module';

.form-calendar-card {
  .permit-type-icon svg {
    height: 18px;
    width: 18px;

    color: get_color('primary', 0);
  }
}
