@import '~/client/src/shared/theme.module';

.bp3-overlay-backdrop {
  background-color: get_color('neutral', 100, 0.3);
}

.confirm-dialog {
  border: 2px solid get_color('neutral', 50);
  border-radius: 3px;
  background-color: get_color('neutral', 100);
  .bp3-dialog-header .bp3-heading {
    color: get_color('primary', 30);
    padding: 15px 0;
  }

  .bp3-dialog-footer-actions {
    justify-content: center;
    padding: 0 20px 20px 10px;
  }
  &-btn-cancel {
    &.bp3-button {
      width: 150px;
      border: 1px solid get_color('primary', 30);
      color: get_color('primary', 30);
      background: get_color('neutral', 100);
      box-shadow: none;
    }
  }
  &-btn {
    &.bp3-button {
      width: 150px;
      background: get_color('primary', 30);
      color: get_color('neutral', 100);
      box-shadow: none;
    }
  }
}
