@import '~/client/src/shared/theme.module';

.attribute-icon {
  margin-left: 8px;

  svg {
    width: 12px;
    height: 12px;

    path {
      fill: get_color('neutral', 0);
    }
  }
}

.overflow-tags {
  max-height: 300px;
  max-width: 350px;
  .attribute-tag-holder {
    display: flex !important;
    align-items: center;
    margin: 4px 0;
    width: fit-content;
    max-width: 100%;
  }
}

.overflow-tags-btn {
  background: get_color('primary', 50);
  border-radius: 12px;

  &:hover {
    background: get_color('primary', 50, 0.2);
  }
}
