@import '~/client/src/shared/theme.module';

$modal-top-offset: 25px;
$border-radius: 10px;
$left-icon-size: 20px;

.compact-popup-modal {
  position: absolute;
  top: 100%;
  height: 0;
  background-color: get_color('neutral', 100);
  z-index: 100;
  width: 100%;
  left: 0;
  transition: top 300ms;
  overflow: hidden;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;

  &.shown {
    top: $modal-top-offset;
    height: calc(100% - #{$modal-top-offset});

    .search-input {
      min-height: 48px;
      color: get_color('primary', 0, 0.38);
    }
  }

  .icon-wrapper {
    left: 10px;

    i,
    svg {
      height: $left-icon-size;
      width: $left-icon-size;
    }
  }

  .cross-icon path:last-child {
    fill: get_color('neutral', 50);
  }

  .base-action-btn {
    right: 10px;
  }
}
