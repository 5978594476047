@import '~/client/src/shared/theme.module';

.compact-workflow-dialog {
  width: 300px !important;
  padding: 0 !important;
  .unscheduled-delivery {
    &.active {
      .unscheduled-delivery-text {
        color: get_color('violet', 40);
      }
      .unscheduled-delivery-icon {
        svg path {
          fill: get_color('violet', 40);
        }
      }
    }
  }
}
