@import '~/client/src/shared/theme.module';

$default-icon-size: 20px;

.compact-header-bar {
  height: 48px;

  &-close-icon {
    fill: get_color('neutral', 50);
  }

  &-icon {
    width: $default-icon-size;
    height: $default-icon-size;

    i,
    svg,
    g {
      width: $default-icon-size;
      height: $default-icon-size;
    }
  }

  &-date-status-row {
    display: flex;
    flex-direction: row;
  }

  &-status > div,
  &-status > span {
    height: 20px !important;

    .hierarchy-delivery-attribute-tag {
      height: 20px !important;
      display: flex;

      .hierarchy-delivery-attribute-tag-content {
        display: flex;
        align-items: center;

        > span {
          font-size: 12px !important;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &-new-request {
    height: 24px;
    box-sizing: border-box;
    line-height: 16px;
  }

  .activity-status > span {
    position: unset !important;
    .on-time-status-icon {
      right: unset !important;
    }
  }
}
