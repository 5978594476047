@import '~/client/src/shared/theme.module';

.chat-badge-container {
  .badge {
    color: get_color('neutral', 100) !important;
    background: get_color('error', 50);
    border: 1px solid get_color('neutral', 100);
    border-radius: 8px;

    min-width: 16px;
    max-height: 16px;

    padding: 0 4px;
    top: -25%;
    right: -30%;
  }
}
