$FILTER_HEADER_HEIGHT: 84px;
$FILTER_FOOTER_HEIGHT: 56px;

$ACTIVITY_ITEM_NODE_HEIGHT: 190px;
$MOBILE_ACTIVITY_ITEM_NODE_HEIGHT: 190px;
$SHOW_MORE_CONTROL_NODE_HEIGHT: 50px;
$SUBHEADER_NODE_HEIGHT: 40px;
$COMPANY_CONTAINER_NODE_HEIGHT: 40px;
$ZONE_CONTAINER_NODE_HEIGHT: 40px;
$DELIVERY_CONTAINER_NODE_HEIGHT: 32px;

$SMALL-SCREEN-WIDTH: 1100px;
$COLLAPSED_SECTION_WIDTH: 18px;

$NOTIFICATION_RULE_CONTAINERS_HEIGHT: 63px;

$SETTINGS-LEFT-SIDEBAR-WIDTH: 260px;
$PERMIT_FORM_PREVIEW_WIDTH: 440px;

$DEFAULT_ICON_SIZE: 16px;

$DEFAULT_BANNER_HEIGHT: 50px;
$DEFAULT_BANNER_DISMISS_ANIMATION_TIME: 200ms;
