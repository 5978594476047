$inbox-item-height: 90px;
$inbox-item-text-height: 40px;

.inbox-container {
  .search-bar-container {
    border-radius: 8px;
  }

  .inbox-item {
    max-height: $inbox-item-height;
    min-height: $inbox-item-height;

    &-text {
      max-height: $inbox-item-text-height;
      min-height: $inbox-item-text-height;

      line-height: 20px;
      word-break: 'break-word';
    }
  }
}
