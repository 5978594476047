@import '~/client/src/shared/theme.module';

.clean-dialog {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 50;
  text-align: right;
  background-color: hsla(0, 0%, 0%, 0.5);
  opacity: 0;
  transition: all 0.2s ease-out;

  &.open {
    opacity: 1;
  }

  .content-container {
    max-width: 80%;
  }

  .dialog-icon {
    margin-top: 0.75rem;
  }

  .dialog-title {
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.31px;
    text-align: center;
    word-break: auto-phrase;
  }

  .dialog-content {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.1px;
    text-align: left;

    * {
      color: get-color('neutral', 30);
    }
  }

  .dialog-action {
    flex: 1;

    & + .dialog-action {
      padding-left: 1rem;
    }
  }
}
