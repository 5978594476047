@import '~/client/src/shared/theme.module';

.mobile-announcement-creation-form {
  .title-row {
    padding-top: 22px;
    width: 100%;

    .announcement-title-icon {
      margin-left: 15px;
    }

    .announcement-title {
      font-size: 25px;
    }
  }

  span[icon^='caret'] {
    width: 24px;
  }

  .section-bar {
    span {
      letter-spacing: 1.5px;
    }

    .section-icon {
      height: 24px;
      svg path {
        fill: get_color('neutral', 100);
        fill-opacity: 1;
      }
    }
  }

  .announcement-text-content {
    height: calc(100vh - 100px);
    .ql-editor {
      width: auto !important;
      margin: 12px 16px 12px 40px !important;
    }
    .announcement-text-editor {
      min-width: 100%;
      max-width: 100%;
    }
  }

  .announcement-text-editor {
    min-width: 100%;
    max-width: 100%;
  }

  .ql-toolbar {
    overflow-x: auto;
    position: sticky;
    bottom: 0;
    justify-content: unset;
  }

  &.is-read-only {
    .announcement-text-content {
      height: 100%;
    }

    .ql-toolbar {
      display: none;
    }
  }
  .file-fullscreen-preview {
    .cross-icon svg {
      path:last-child {
        fill: get_color('primary', 30);
      }
    }
  }
}
