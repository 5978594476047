@import '~/client/src/shared/theme.module';

.permit-types-container {
  .header {
    .bp3-icon-chevron-left path {
      fill: get_color('primary', 50);
    }

    svg {
      fill: get_color('neutral', 50);
    }
  }
}
