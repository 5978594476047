@import '~/client/src/shared/theme.module';

.tags-input-wrapper {
  .tags-input-field {
    min-height: 40px;
  }

  .tags-suggestions {
    max-height: 150px;
    background-color: get_color('neutral', 100);
    z-index: 3;

    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .base-action-btn {
      height: 25px;
    }
  }

  .tag-wrapper {
    max-width: 200px;

    &.outlined {
      border: 2px solid get_color('primary', 70);
      border-radius: 4px;
    }
  }
}
