@import '~/client/src/shared/theme.module';

.georeferencing-label {
  .geo-icon {
    height: 20px;
  }
  .georeferenced-label {
    padding: 0 5px;

    &.referenced {
      border: 1px solid get_color('success', 40);
      background-color: get_color('success', 88);
    }
    &.not-referenced {
      border: 1px solid get_color('neutral', 70);
      background-color: get_color('neutral', 84);
    }
  }
}
