@import '~/client/src/shared/theme.module';

.delivery-tree {
  .level-tree-node {
    .content {
      min-height: $DELIVERY_CONTAINER_NODE_HEIGHT;
      max-height: $DELIVERY_CONTAINER_NODE_HEIGHT;
    }
  }
}
.activities-tree {
  .level-1 {
    background-color: get_color('primary', 20);
    color: get_color('neutral', 100);

    .handle-icon {
      color: get_color('neutral', 100);
    }
  }
  .level-2 {
    background-color: get_color('violet', 20);
    color: get_color('neutral', 100);

    .handle-icon {
      color: get_color('neutral', 100);
    }
  }

  .level-3 {
    background-color: get_color('neutral', 0);
    color: get_color('neutral', 100);

    .handle-icon {
      color: get_color('neutral', 100);
    }
  }

  .level-4 {
    background-color: get_color('primary', 84);
    color: get_color('primary', 30);

    .handle-icon {
      color: get_color('primary', 30);
    }
  }
  .level-5 {
    background-color: get_color('primary', 96);
    color: get_color('neutral', 50);

    .handle-icon {
      color: get_color('primary', 30);
    }
  }

  .company-tree-node {
    .content {
      min-height: $COMPANY_CONTAINER_NODE_HEIGHT;
      max-height: $COMPANY_CONTAINER_NODE_HEIGHT;
    }
  }

  .sub-tree-node {
    min-height: $SUBHEADER_NODE_HEIGHT;
    max-height: $SUBHEADER_NODE_HEIGHT;
  }

  .activity-tree-node {
    min-height: $ACTIVITY_ITEM_NODE_HEIGHT;
    max-height: #{$ACTIVITY_ITEM_NODE_HEIGHT + 21px};

    &.mobile {
      min-height: $MOBILE_ACTIVITY_ITEM_NODE_HEIGHT;
      max-height: #{$MOBILE_ACTIVITY_ITEM_NODE_HEIGHT + 21px};

      .status-update-input-value {
        pointer-events: none;
      }
    }
  }

  .show-more-control-node {
    min-height: $SHOW_MORE_CONTROL_NODE_HEIGHT;
    max-height: $SHOW_MORE_CONTROL_NODE_HEIGHT;
  }

  .content {
    width: 100%;
    height: 100%;
  }

  .zone-tree-node,
  .level-tree-node,
  .activity-code-tree-node {
    .content {
      min-height: $ZONE_CONTAINER_NODE_HEIGHT;
      max-height: $ZONE_CONTAINER_NODE_HEIGHT;
    }

    .delivery-icon {
      svg * {
        stroke: get_color('neutral', 100);
        fill: get_color('neutral', 100);
        color: get_color('neutral', 100);
      }
    }
  }
}
