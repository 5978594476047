@import '~/client/src/shared/theme.module';

.bp3-tooltip-calendar-event {
  .bp3-popover-target {
    width: 100%;
    height: 100%;
  }
}

.calendar-day-view {
  .swipeable-day {
    position: relative;

    @keyframes swipe-left {
      0% {
        opacity: 0;
        transform: translateX(0%);
      }
      10% {
        opacity: 0;
        transform: translateX(105%);
      }
      40% {
        opacity: 0;
      }
      100% {
        opacity: 1;
        transform: translateX(0%);
      }
    }

    @keyframes swipe-right {
      0% {
        opacity: 0;
        transform: translateX(0%);
      }
      10% {
        opacity: 0;
        transform: translateX(-105%);
      }
      40% {
        opacity: 0;
      }
      100% {
        opacity: 1;
        transform: translateX(0%);
      }
    }

    $animation-duration: 500ms;

    &.left-swipe {
      animation: swipe-left $animation-duration ease-out 1;
    }

    &.right-swipe {
      animation: swipe-right $animation-duration ease-out 1;
    }
  }

  &-event {
    display: inline-block;
    position: absolute;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 4px;
    box-sizing: border-box;
    user-select: none;
    z-index: 2;

    &.disabled-border {
      border: none !important;
    }

    &:not(.selected-event) {
      background: get_color('neutral', 100) !important;
    }

    &.highlighted-event {
      transform: scale(1.05);
      box-shadow: 0px 0px 34px 0px get_color('neutral', 0, 0.4);
      z-index: 3;
    }

    &.selected-event {
      color: get_color('neutral', 100);
      background-color: get_color('neutral', 0);
      padding: 0 5px;
    }

    .rotated-event-label {
      transform: rotate(90deg);
      transform-origin: top left;
      left: calc(100% + 6px);
    }

    .event-card-background {
      left: 0;
      position: absolute;
      z-index: -1;
    }

    .delivery-icon {
      width: $DEFAULT_ICON_SIZE !important;
      height: $DEFAULT_ICON_SIZE !important;
      display: inline-flex !important;
      vertical-align: sub;
    }

    .delivery-concrete-icon {
      width: 24px !important;
      height: 22px !important;
      display: inline-flex !important;
      vertical-align: sub;
    }

    &.unclickable-element.new {
      min-width: 375px;
      border-bottom: 1px solid get_color('neutral', 88, 1);
      box-shadow: 0px 0px 20px get_color('neutral', 0, 0.5);
      transform: initial;
      padding: 12px 17px 14px;

      &:before {
        content: ' ';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 4px;
        background-color: currentColor;
        border-radius: 4px 4px 0px 0px;
      }
    }

    &:hover {
      .event {
        &-card-background {
          background-color: currentColor !important;
        }

        &-icon {
          color: get_color('neutral', 100);
        }
      }

      .text {
        color: get_color('neutral', 100);
      }
    }
  }

  .hour-labels-container {
    flex: 1 1 80px;
    padding-top: 8px;
  }
}

.bp3-tooltip-calendar-event {
  .bp3-popover,
  .bp3-tooltip {
    box-shadow: unset;
  }
  .bp3-popover-arrow {
    display: none;
  }
  .bp3-popover-content {
    background-color: unset !important;
  }
}
