@import '~/client/src/shared/theme.module';

.announcement-list-item {
  .title-row {
    justify-content: space-between;
  }

  .announcement-icon {
    svg {
      circle {
        fill: get_color('error', 40);
      }
    }
  }

  .content-section > *:first-child {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  .image-previews-section {
    .last-image {
      height: 70px;
      width: 70px;
      max-width: 70px;
      border-radius: 4px;
      overflow: hidden;

      .text {
        width: 100%;
        text-align: center;
        line-height: 70px;
        background-color: get_color('primary', 0, 0.62);
      }
    }

    img {
      height: 70px;
      width: 70px;
      max-width: 70px;
      border-radius: 4px;
      object-fit: cover;
    }
  }

  .video div {
    // Overriding styles of the supplied video player
    width: 100% !important;
  }
}
