@import '~/client/src/shared/theme.module';

@mixin text-date-label {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  font-style: normal;
  font-stretch: normal;
}

.daily-mobile-component {
  display: flex;
  height: 100vh;
  flex-direction: column;
  overflow: hidden;

  .delivery-bar {
    padding: 15px;
    background-color: get_color('neutral', 100);
    font-size: 12px;
    display: flex;
    flex-direction: column;
    min-height: 76px;

    .delivery-bar-item {
      display: flex;
      padding: 5px;

      img {
        padding-right: 10px;
        align-self: center;
        height: 16px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  // This is here specifically to fix a semantic-ui style on mobile
  .activity-list::-webkit-scrollbar {
    width: 0;
  }

  .activity-list-header {
    min-height: 65px;
  }

  .daily-report-submission {
    display: flex;
    justify-content: center;
    align-items: center;
    color: get_color('neutral', 100);
    background-color: get_color('primary', 30);
    height: 40px;
    font-size: 16px;
  }

  .duration-endpoints {
    .text {
      color: get_color('neutral', 50);
      @include text-date-label;
    }
  }

  .duration-days {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .text {
      font-size: 14px;
      @include text-date-label;
    }
  }

  .loader {
    margin: auto;
  }
}

.flipped-img {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

// common style for activity list
.daily-mobile-component,
.zone-map-sidebar,
.logistics-side-bar {
  .activity-list {
    height: 100%;
    overflow-x: hidden;

    .ReactVirtualized__Table__row,
    .ReactVirtualized__Table__rowColumn {
      margin: 0;
      text-overflow: unset;
      white-space: normal;
    }

    .activity-item {
      overflow-x: hidden;
      display: flex;
      width: 100%;
      min-height: $ACTIVITY_ITEM_NODE_HEIGHT;
    }

    .activity-item::-webkit-scrollbar {
      height: 0;
    }

    .activity-item-main-col {
      background: get_color('neutral', 100);
      display: flex;
      width: 100%;
      flex-shrink: 0;
    }

    .activity-item-data-col {
      background: get_color('neutral', 100);
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .activity-item-title {
      font-size: 14px;
      color: get_color('primary', 30);
    }

    .activity-item-details {
      color: get_color('neutral', 70);
      font-size: 12px;
    }

    .activity-item-button-col {
      width: 140px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: auto;
    }

    .activity-item-button {
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 40px;
      border-radius: 2px;
      border: solid 1px get_color('grey', 80);
      align-self: center;
    }
  }

  .activity-item-options-col {
    color: get_color('neutral', 100);
    border-left: 1px solid get_color('neutral', 100);
    width: 154px;
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
    background-color: get_color('primary', 30);

    .activity-item-option {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    & img {
      height: 20px;
      width: auto;
    }

    // For flag icon; remove when placeholder gone
    & > div:first-of-type > img {
      margin-top: 2px;
      margin-bottom: -2px;
    }

    & > div {
      width: 100%;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      div {
        margin-top: 8px;
      }
    }
  }

  .video div {
    max-width: 90%;
  }
}
