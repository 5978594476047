@import '~/client/src/shared/theme.module';

.preview-image-wrapper {
  position: relative;
  overflow: hidden;
  width: inherit;
  height: inherit;
  .remove-icon {
    svg path {
      fill: get_color('neutral', 100);
    }
  }
}

.preview-image-container {
  background-color: get_color('slate', 84);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  overflow: hidden;
}
