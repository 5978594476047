@import '~/client/src/shared/theme.module';

$select-button-size: 18px;

.select-block {
  display: flex;
  color: get_color('neutral', 0);
  .select-button {
    width: $select-button-size;
    height: $select-button-size;
    flex-grow: unset;
    background-color: get_color('neutral', 100);
    padding: 0;

    .select-chevron {
      fill: get_color('neutral', 50);
    }
  }
}
