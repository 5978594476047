@import '~/client/src/shared/theme.module';

.dimmer {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  background: get_color('neutral', 0);
  z-index: 9;
  bottom: 0px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 500ms;
  will-change: opacity;
}

.qr-code-container {
  max-height: 250px;
  margin: 40px 0 40px 0;
}

.dimmer.shown {
  opacity: 0.4;
  pointer-events: auto;
}

.sidebar {
  width: 85%;
  height: 100%;
  margin: 0;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  bottom: 0;
  background: get_color('neutral', 100);
  transition: transform 333ms cubic-bezier(0.55, 0, 0.1, 1);
  padding-left: 0px;
  font-size: 14px;

  &.closed {
    transform: translate(-101%, 0px);
    box-shadow: none;
  }

  &.opened {
    transform: translate(0px, 0px);
    box-shadow: 1px 1px 10px get_color('neutral', 0, 0.4);
  }

  .header-item {
    width: 100%;
    height: auto;
    padding: 30px 20px;
    // Note: this is to fix the bunk left-padding based on the element
    padding-left: 5px;
    border-bottom: 1px solid get_color('slate', 90);
  }

  .item {
    width: 100%;
    border-bottom: 1px solid get_color('slate', 90);

    &-status .icon {
      width: 1.6em;
      margin-right: 1em;
    }

    &-title .icon {
      width: 1.6em;
      margin-right: 2em;
    }

    &-title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &-status {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &-status-text {
      min-width: 15px;
    }
  }
}

.content.dimmed:after {
  position: fixed;
  top: 0;
  right: 0;
  content: '';
  background-color: get_color('neutral', 100, 0.4);
  overflow: hidden;
  transition: opacity 500ms;
  will-change: opacity;
}
