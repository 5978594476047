@import '~/client/src/shared/theme.module';

.announcement-creation-form-details {
  &.side-bar {
    -ms-flex: 0 0 400px;
    flex: 0 0 400px;
    width: 400px;
  }

  .layout-content {
    overflow-y: auto;
    padding-top: 16px;

    > * {
      flex: none !important;
    }

    .button-container {
      margin-top: auto;
      padding: 16px;
    }
  }

  .common-required-field {
    color: get_color('primary', 40) !important;
    font-size: 12px;
  }

  .apply-button {
    height: 32px;
    border-radius: 4px;
    background-color: get_color('primary', 40);
  }

  .announcement-input {
    height: 48px;
    background-color: get_color('neutral', 96);
    border-bottom: 1px solid get_color('neutral', 60);
    border-radius: 4px 4px 0 0;

    .bp3-icon {
      path {
        fill: get_color('neutral', 60);
      }
    }
  }

  .closure-selector .p-checkbox-box {
    border: 1px solid get_color('primary', 0, 0.38);
    color: get_color('primary', 0, 0.38) !important;
  }

  .blue-text {
    color: get_color('primary', 40);
  }

  .announcement-title-input {
    height: auto;
    resize: none;
    background-color: get_color('neutral', 96);
    border-bottom: 1px solid get_color('neutral', 60);
    border-radius: 4px 4px 0 0;
    font-size: 16px;
    padding: 14px 12px;

    .bp3-icon {
      path {
        fill: get_color('neutral', 60);
      }
    }
  }

  .field-icon svg {
    width: 20px;
    height: 20px;
    path {
      fill: get_color('primary', 0, 0.38);
      fill-opacity: unset;
    }
  }

  .location-selector {
    .hierarchy-delivery-attribute-tag {
      width: fit-content;
    }

    .location-select {
      display: flex;

      .bp3-popover-target {
        width: 100%;
      }
    }
  }

  .bp3-icon.bp3-icon-chevron-left svg {
    width: 30px;
    height: 30px;

    path {
      fill: get_color('neutral', 60);
    }
  }

  .announcement-order-set-up {
    .swipable-date-picker {
      .week-day {
        &.today {
          color: unset;
        }

        &.selected {
          background-color: get_color('primary', 50);
          color: get_color('neutral', 100);
        }
      }
    }
  }
}
