@import '~/client/src/shared/theme.module';

.qr-codes {
  .qr-items-list {
    height: calc(100% - 100px);
    .qr-small-holder {
      height: 100px !important;
    }
  }

  .qr-codes-scanner-active {
    .modal-header {
      padding: 10px 20px !important;
    }
  }

  .dot {
    min-width: 12px;
    height: 12px;
    width: 12px;
    background-color: get_color('neutral', 40);
    border-radius: 55%;

    &.active {
      background-color: get_color('secondary', 40);
    }
  }

  .qr-log {
    height: calc(100% - 60px);
  }

  .base-action-btn {
    height: 40px;
    border-radius: 100px;
    gap: 8px;
  }

  .base-action-btn-title {
    padding: 10px !important;
  }

  .navigation-icon {
    height: 32px;
  }
}

.fullscreen-scanner {
  left: 0;
  top: 0;
  z-index: 101;
}
