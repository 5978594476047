@import '~/client/src/shared/theme.module';

.delivery-status-selector {
  height: 100%;

  &-icon {
    position: absolute;
    top: 12px;
    right: 20px;

    &-container {
      position: relative;
      width: 60px;
      height: 40px;
      overflow: hidden;
    }

    &-line {
      background-color: get_color('neutral', 84);
      height: 100%;
      margin-left: 30px;
      width: 1px;

      &.first {
        height: 50%;
        margin-top: 20px;
      }

      &.last {
        height: 50%;
      }
    }

    &-footer {
      position: absolute;
      bottom: 0;
    }

    &.dot {
      width: 8px;
      height: 8px;
      border: 1px solid get_color('neutral', 0);
      border-radius: 4px;
      background-color: get_color('neutral', 0);
      top: 16px;
      left: 26px;

      &.empty {
        background-color: get_color('neutral', 100);
      }
    }
  }

  .action-btn {
    border: 1px solid get_color('primary', 30);
    border-radius: 5px;
    color: get_color('primary', 30);
    width: 70px;
    text-align: center;
    padding: 5px;
    margin-right: 10px;

    &.disabled {
      pointer-events: none;
      border: 1px solid get_color('neutral', 84);
      color: get_color('neutral', 84);
    }

    @mixin active-button($color) {
      background-color: $color;
      border: 1px solid $color;
      color: get_color('neutral', 100);
    }

    &.active:first-child {
      @include active-button(get_color('error', 50));
    }

    &.active:last-child {
      @include active-button(get_color('primary', 30));
    }
  }
}
