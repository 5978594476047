@import '~/client/src/shared/theme.module';

.logistics-carousel {
  bottom: 5px;
  z-index: 8;

  .logistics-cards {
    &-container {
      transition: transform 0.5s ease;
      width: calc(100% - 28px);
      margin-left: 12px;
      .logistics-card {
        width: 100%;
        box-shadow: 0px 4px 8px 0px get_color('neutral', 0, 0.25);

        .workflow-card {
          max-height: 600px;
          -webkit-transform: translate3d(0, 0, 0);
          overflow-y: auto !important;
          overflow-x: hidden !important;
          white-space: normal;
          .expendable-text {
            padding-left: 0;
          }
        }
      }
    }
  }
}
