@import '~/client/src/shared/theme.module';

$icons-size: 20px;

.compact-group-by-container {
  i,
  svg {
    width: $icons-size !important;
    height: $icons-size !important;
  }

  .group-by-option {
    height: 50px;

    &.active {
      div {
        color: get_color('primary', 70) !important;
      }

      .p-checkbox-box {
        border-color: get_color('primary', 70) !important;

        .pi-check:before {
          background-image: radial-gradient(
            get_color('primary', 70),
            get_color('primary', 70) 40%,
            transparent 40%
          ) !important;
        }
      }

      .common-icon svg g path,
      .company-icon svg g rect,
      .hierarchy-icon svg g,
      .checkmark-icon svg g use,
      .material-icon svg path,
      .person-icon svg path,
      .building-icon svg g rect {
        fill: get_color('primary', 70);
      }

      .gate-icon svg g path {
        stroke: get_color('primary', 70);
      }

      .delivery-icon svg path {
        stroke: get_color('primary', 70);
        fill: get_color('primary', 70);
      }
    }
    &:not(.active) {
      .p-checkbox-box {
        border-color: get_color('neutral', 50) !important;
      }

      .common-icon svg g path,
      .company-icon svg g rect,
      .hierarchy-icon svg g,
      .checkmark-icon svg g use,
      .material-icon svg path,
      .person-icon svg path,
      .building-icon svg g rect {
        fill: get_color('neutral', 0);
      }

      .gate-icon svg g path {
        stroke: get_color('neutral', 0);
      }

      .delivery-icon svg path {
        stroke: get_color('neutral', 0);
        fill: get_color('neutral', 0);
      }
    }
  }
}
