@import '~/client/src/shared/theme.module';

.camera-upload-button-container {
  display: flex;
  align-items: center;
  position: relative;

  button.camera-upload-button {
    margin: 0;
    color: get_color('neutral', 100);
    border: none;
    background: none;
    padding: 0;

    &.active g {
      stroke: get_color('primary', 30);
    }
    &.blue g {
      stroke: get_color('violet', 40);
      fill: get_color('violet', 40);
    }
  }

  .hidden-input {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: -10;
    width: 1px !important;
  }
}
