@import '~/client/src/shared/theme.module';

.logistics-list-card {
  .main-icon svg {
    width: 22px;
    height: 22px;
    padding: 2px;
  }
  .location-icon i svg {
    width: 22px;
    height: 22px;
    padding: 2px;
  }
  .break-word {
    word-break: break-word;
  }
}
