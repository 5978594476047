@import '~/client/src/shared/theme.module';

.sitemap-picker-holder {
  z-index: 1000000;
  &.filter-holder {
    &.shown {
      top: 5px;
      height: calc(100% - 5px);
    }
  }

  .name-line {
    max-width: calc(100% - 100px);
  }

  .sitemaps-options-header {
    .cross-icon {
      left: 0 !important;
      border: none !important;
      box-shadow: none !important;
    }

    .cross-icon path:last-child {
      fill: get_color('neutral', 30) !important;
    }
  }
  .sitemaps-search-box {
    height: 38px;
    background-color: get_color('neutral', 96);
    input {
      background-color: get_color('neutral', 96);
      color: get_color('neutral', 0);
      border: none;
      min-width: 0px;
      outline: none;
    }
  }
  .sitemaps-options-list {
    .sitemap-option {
      height: 80px;
      .file-preview {
        width: 75px;
        height: 56px;
        img {
          width: 75px;
          height: 56px;
        }
      }
      &.selected {
        background-color: get_color('violet', 0, 0.08);
      }
      .setup-form-map,
      .setup-form-map-container {
        width: 75px;
        height: 65px;
      }
      .setup-form-map-buttons,
      .mapboxgl-ctrl-bottom-left,
      .mapboxgl-ctrl-top-right,
      .mapboxgl-ctrl-bottom-right {
        display: none;
      }
    }
  }
}
