$big-dot-size: 6px;
@mixin default-bp3-outline {
  outline: get_color('primary', 50, 0.4) auto 2px;
  outline-offset: 2px;
}

@mixin separator-row {
  content: '';
  border-bottom: 1px solid get_color('neutral', 84);
}

.category-separator::before {
  min-width: 40px;
  max-width: 40px;
  @include separator-row;
}

.category-separator::after {
  width: 100%;
  @include separator-row;
}

@mixin big-dot($color) {
  content: '';
  display: inline-block;
  height: $big-dot-size;
  width: $big-dot-size;
  border-radius: 4px;
  background-color: $color;
  position: absolute;
  bottom: 4px;
  left: calc(50% - (#{$big-dot-size} / 2));
}

@mixin bare-input {
  padding: 0;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
