.expandable-text {
  overflow: hidden;
  clear: both;

  &.collapsed {
    max-height: 60px;
  }

  .more-less-toggler {
    position: absolute;
    bottom: 0;
    right: 0;

    .is-active {
      cursor: pointer;
    }
  }

  img {
    max-width: 100%;
  }
}
