@import '~/client/src/shared/theme.module';

.delivery-ticket {
  .project-title {
    font-size: 18px;
  }
  .text {
    &.orange {
      color: get_color('secondary', 50);
    }
    .status {
      font-size: 14px;
      line-height: 20px;
      background-color: get_color('primary', 92);
      border: 1px solid get_color('primary', 90);
      border-radius: 12px;
      padding: 2px 6px;
      &.changed-status {
        background-color: get_color('magenta', 96);
        border-color: get_color('error', 84);
      }
    }
    &.semi-transparent {
      color: get_color('primary', 0, 0.38);
    }
  }

  .details-field-name {
    color: get_color('primary', 0, 0.38);
    line-height: 16px;
  }
  .details-field-value {
    white-space: break-spaces;
    padding-bottom: 3px;
    margin-left: 23px;
  }

  .details-icon {
    margin-right: 5px;
    display: unset;
    flex-grow: 0;
    width: 20px !important;
    height: 20px !important;
    svg {
      width: 20px;
      height: 20px;
      path,
      g {
        fill: get_color('primary', 0, 0.38);
        fill-opacity: 1;
      }
    }
  }
  .link {
    color: get_color('primary', 40);
    margin-left: 23px;
  }
}
