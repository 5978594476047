@import '~/client/src/shared/theme.module';

$modal-width: 375px;
$max-modal-height: 332px;
$max-elements-height: 300px;
$header-height: 30px;

.sitemap-draggable-modal {
  position: absolute;
  min-width: $modal-width;
  width: fit-content;
  max-width: 395px;
  min-height: 50px;
  height: fit-content !important;
  max-height: $max-modal-height;
  background-color: get_color('neutral', 100);

  z-index: 2;

  &.short-modal {
    min-width: 335px !important;
  }

  header {
    min-height: $header-height;
    background-color: get_color('neutral', 0);
    cursor: move;

    .hierarchy-delivery-attribute-tag-content {
      color: get_color('neutral', 100);
    }
  }

  @mixin elements-list {
    height: fit-content !important;
    max-height: $max-elements-height !important;
    width: $modal-width !important;
  }

  .draggable-elements-list {
    @include elements-list;
  }
}
