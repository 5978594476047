@import '~/client/src/shared/theme.module';

.hierarchy-delivery-attribute {
  &-tag {
    overflow: hidden;
    display: inline-flex;
    max-width: fit-content;

    .tag-background {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0.2;
      z-index: 1;
    }

    &-content {
      white-space: nowrap;
      z-index: 2;
    }

    &-icon {
      z-index: 2;
    }

    &-remove-icon {
      z-index: 2;
      color: get_color('neutral', 60);
    }
  }
}
