@import '~/client/src/shared/theme.module';

.weather-label {
  width: 100%;
  height: 32px;

  .weather-icon,
  .weather-icon svg {
    $icon-size: 24px;

    width: $icon-size !important;
    height: $icon-size !important;
  }

  .precipitation-label {
    color: get_color('primary', 40) !important;
  }
}
