@import '~/client/src/shared/theme.module';

.multiselect-checkbox-item {
  .only-button {
    display: none;
    margin-left: 10px;
    font-size: 12px;
    color: get_color('neutral', 50);
    border: 1px solid get_color('neutral', 84);
    border-radius: 10px;
    padding: 0 3px;
    cursor: pointer;

    &:hover {
      background-color: get_color('primary', 88, 0.5);
    }
  }

  &:hover {
    .only-button {
      display: block;
    }
  }
}
