@import '~/client/src/shared/theme.module';

$max-icon-size: 22px;

.section-icon-container i {
  svg {
    height: $max-icon-size;
    width: $max-icon-size;
    color: get_color('primary', 30);
    fill: get_color('primary', 30);
  }
  &.dark svg {
    color: get_color('neutral', 0, 1) !important;
    fill: get_color('neutral', 0, 1) !important;
  }
}
