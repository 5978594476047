@import '~/client/src/shared/theme.module';

.layout-content > div:not([class]) {
  width: inherit;
  height: inherit;
}
.layout-view-root {
  background: get_color('neutral', 100);
  .displayed-header {
    height: calc(100% - 60px) !important;
  }
}
.layout-view-root > div:not([class]) {
  width: inherit;
  height: inherit;
}

.layout-hbox {
  display: flex;
  flex-direction: row;
}

.layout-hbox > * {
  flex: 1;
}
.layout-vbox > * {
  flex: 1;
}

.layout-vbox {
  display: flex;
  flex-direction: column;
}

.layout-navigation {
  z-index: 102;
}

.layout-footer {
  z-index: 20;
}
