@import '~/client/src/shared/theme.module';

@mixin active-button {
  background-color: get_color('primary', 30);
  color: get_color('neutral', 100);
}

.activity-filters-page {
  overflow: hidden;
  position: relative;

  .reset-btn {
    font-size: 12px;
    padding: 2px 5px;
    border-radius: 10px;
    background-color: get_color('neutral', 100);
    border: 1px solid get_color('neutral', 50);
    color: get_color('neutral', 50);

    &:active {
      @include active-button;
    }
  }

  .filter-icon {
    color: get_color('primary', 30);
    transform: scale(0.8);
    vertical-align: middle;
    margin-left: 5px;
  }

  .select-all-button {
    font-size: 12px;
    width: 65px;
    padding: 2px 0;
    border-radius: 10px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;

    &.blue-btn {
      background-color: get_color('primary', 30);
      border: 1px solid get_color('primary', 30);
      color: get_color('neutral', 100);
    }

    &.white-btn {
      background-color: get_color('neutral', 100);
      border: 1px solid get_color('neutral', 50);
      color: get_color('neutral', 50);
    }
  }

  .multiselect-checkbox-item {
    margin-bottom: 20px;

    .filter-label {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 70vw;
      white-space: nowrap;
      display: inline-block;
      line-height: 1.1;

      &.disabled {
        opacity: 0.4;
      }
    }
  }
  .btn {
    width: 100%;
    text-align: center;
    border-radius: 6px;
    padding: 7px;
    cursor: pointer;

    &.apply-button {
      background-color: get_color('primary', 30);
      border: 1px solid get_color('primary', 30);
      color: get_color('neutral', 100);
    }

    &.save-button {
      background-color: get_color('neutral', 100);
      border: 1px solid get_color('neutral', 84);
      color: get_color('neutral', 50);
    }
  }
}

.custom-filter-dialog-controls {
  display: flex;

  .delete-btn {
    width: 35%;
    margin-right: 20px;

    &.hide {
      display: none;
    }
  }
}
