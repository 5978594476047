@import '~/client/src/shared/theme.module';

.get-the-app-banner {
  height: $DEFAULT_BANNER_HEIGHT;
  background-color: get-color('neutral', 20);
  overflow-y: hidden;
  transition: height ease-in $DEFAULT_BANNER_DISMISS_ANIMATION_TIME;

  &.banner--hidden {
    height: 0;
  }

  & > * {
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
  }

  .close {
    flex: 0 1 auto;
  }

  .body-text {
    flex-grow: 1;
    flex-shrink: 0;
    padding: unset;
  }

  .buttons {
    flex-shrink: 1;
    flex-grow: 0;
  }
}
