@import 'sizes';

// palette link: https://www.figma.com/file/hhcD6BzIe5ux7PEfGB0ySv/000-StruxHub-Design-System?node-id=6786%3A809254

// list of 'SEED' colors
$base-colors: (
  'neutral': (
    240,
    4%,
  ),
  'primary': (
    211,
    88%,
  ),
  'blue': (
    218,
    85%,
  ),
  'error': (
    0,
    71%,
  ),
  'warning': (
    50,
    96%,
  ),
  'success': (
    138,
    62%,
  ),
  'purple': (
    267,
    52%,
  ),
  'secondary': (
    38,
    100%,
  ),
  'emerald': (
    161,
    58%,
  ),
  'magenta': (
    327,
    69%,
  ),
  'violet': (
    242,
    62%,
  ),
  'green': (
    111,
    60%,
  ),
  'brown': (
    18,
    37%,
  ),
  'turqoise': (
    182,
    66%,
  ),
  'slate': (
    200,
    15%,
  ),
  'grey': (
    30,
    7%,
  ),
);

// function to get color hex value. base-color-name is th 'SEED' color, l is tone value
@function get_color($base-color-name, $l, $alpha: 1) {
  $hs: map-get($base-colors, $base-color-name);
  $color-l: var(#{'--tone' + $l});

  @return hsla(nth($hs, 1), nth($hs, 2), $color-l, $alpha);
}

:export {
  neutral0: get_color('neutral', 0, 1); // #202028
  neutral50_4: get_color('neutral', 50, 0.4); // #20202899
  neutral60: get_color('neutral', 60, 1); // #a8acb2
  neutral60_1: get_color('neutral', 60, 0.1); // #a8acb2
  neutral70: get_color('neutral', 70, 1); // #a6a6a9
  neutral84: get_color('neutral', 84, 1); // #dbdbdc
  neutral96: get_color('neutral', 96, 1); // #f4f5f6
  neutral100: get_color('neutral', 100, 1); // #ffffff

  primary20: get_color('primary', 20, 1); // #093f9b
  primary40: get_color('primary', 40, 1); // #0838E2
  primary60: get_color('primary', 60, 1); // #00b5ec
  primary70: get_color('primary', 70, 1); // #4379ab
  primary90: get_color('primary', 90, 1); // #d3e4f8

  success40: get_color('success', 40, 1); // #00a54d

  error40: get_color('error', 40, 1); // #ea9600
  error50: get_color('error', 50, 1); // #ea3500
  error70: get_color('error', 70, 1); // #ee675c
  error88: get_color('error', 88, 1); // #ffccd3

  grey88: get_color('grey', 88, 1);
  grey96: get_color('grey', 96, 1); // #e6e6e4

  warning40: get_color('warning', 40, 1); // #dfbf03
  warning70: get_color('warning', 70, 1); // #fecf33
  warning88: get_color('warning', 88, 1); // #fff5d6

  green20: get_color('green', 20, 1); // #1e8e3a
  green30: get_color('green', 30, 1); // #39a443
  green50: get_color('green', 50, 1); // rgba(151, 241, 123, 0.6)
  green60: get_color('green', 60, 1); // #75d864
  green70: get_color('green', 70, 1); // #8adc4c
  green88: get_color('green', 88, 1);

  secondary40: get_color('secondary', 40, 1); // #cb6f10
  secondary50: get_color('secondary', 50, 1); // #e8871a
  secondary60: get_color('secondary', 60, 1); // #fec928

  turqoise50: get_color('turqoise', 50, 1); // #00c0c7
  turqoise60: get_color('turqoise', 60, 1); // #61b9b2

  purple30: get_color('purple', 30, 1); // #56057a
  purple40: get_color('purple', 40, 1); // #6f38b1
  purple92: get_color('purple', 92, 1); // #f0d7e7

  slate50: get_color('slate', 50, 1); // #78909c
  slate60_3: get_color('slate', 60, 0.3); // rgba(93, 136, 179, 0.7)

  magenta40: get_color('magenta', 40, 1); // #b02982
  magenta50: get_color('magenta', 50, 1); // #da3490
  magenta70: get_color('magenta', 70, 1); // #daafcd
  magenta80: get_color('magenta', 80, 1); // #f5c6d8

  violet50: get_color('violet', 50, 1); // #4944d3
}
