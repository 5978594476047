@import '~/client/src/shared/theme.module';

$icon-size: 20px;
$header-height: 50px;

.file-fullscreen-preview {
  .navigation-icon {
    height: 40px;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 50%;
    cursor: pointer;
    background-color: transparentize($color: black, $amount: 0.25);

    svg {
      width: 20px;
      height: 20px;
      path {
        fill: get_color('neutral', 100);
        fill-opacity: 1;
      }
    }

    &-wrapper-right {
      right: 0;
      z-index: 1;
    }
    &-wrapper-left {
      left: 0;
      z-index: 1;
    }
  }

  position: absolute;
  width: 100%;
  left: 0;
  height: 100%;
  top: 0;
  background-color: transparentize($color: black, $amount: 0.15);
  z-index: 100;

  .img-viewer {
    max-width: calc(100vw - 30px);
    max-height: calc(100vh - 110px);
    &.swipeable {
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }

  .react-transform-wrapper {
    overflow: visible;
  }

  .swipeable-image-container {
    transition: transform 0.5s ease;
    width: calc(100% - 24px);
    margin-left: 12px;
    .swipeable-image {
      width: 100%;
      -webkit-transform: translate3d(0, 0, 0);
      overflow-y: auto !important;
      overflow-x: hidden !important;
      white-space: normal;
    }
  }

  .top-menu-image-holder {
    background: linear-gradient(
      to bottom,
      get_color('primary', 0, 0.35) 0%,
      transparent 100%
    );
  }
  .top-menu-pdf-holder {
    background-color: get_color('neutral', 0);
  }
  .top-menu-pdf-holder,
  .top-menu-image-holder {
    position: absolute;
    z-index: 1000;
    width: 100%;
    overflow: auto;

    .text {
      line-height: 40px;
    }
  }

  .bottom-panel-holder {
    position: absolute;
    bottom: 10px;
    .bottom-menu {
      background-color: get_color('primary', 0, 0.25);
    }
  }

  .comment-holder {
    width: 90%;
  }

  .zoom-box-pdf-holder {
    position: relative;
    height: 100%;
    transform: translate3d(0, 0, 0) !important;
    .react-pdf__Document {
      height: 100%;
      .react-pdf__Page {
        user-select: none;
        overflow: auto;
        position: absolute !important;
        top: $header-height;
        width: 100%;
        height: calc(100% - #{$header-height});
        background-color: get_color('neutral', 60);
        .react-pdf__Page__canvas {
          margin: auto;
        }
      }
    }

    .pdf-page-loader {
      top: $header-height;
    }
  }
  .toolbar-icon {
    display: inline-flex;
    svg {
      width: 24px !important;
      height: 24px !important;

      path {
        fill: get_color('neutral', 100);
        fill-opacity: 1;
      }
    }
  }

  .bottom-toolbar-icon {
    display: inline-flex;
    svg {
      width: $icon-size !important;
      height: $icon-size !important;

      path:last-child {
        fill: get_color('neutral', 100);
      }
    }
  }

  .cross-icon {
    display: inline-flex;
    z-index: 2;
    svg {
      width: 24px !important;
      height: 24px !important;

      path:last-child {
        fill: get_color('neutral', 100);
      }
    }
  }

  .image-icon {
    display: inline-flex;
    z-index: 2;
    svg {
      width: 24px !important;
      height: 24px !important;

      path:last-child {
        fill: get_color('error', 50);
        fill-opacity: 1;
      }
    }
  }

  &-content {
    height: calc(100% - 60px);
  }
}
