@import '~/client/src/shared/theme.module';

.dp-container {
  width: 100%;
  background-color: get_color('neutral', 100);
  display: block;
  opacity: 1;
  transition: all 400ms;
  padding-inline: 15px;

  .cross-icon,
  .arrow-back-icon {
    display: inline-flex;

    svg {
      width: 26px;
      height: 26px;

      path:last-child {
        fill: get_color('neutral', 50);
      }
    }
  }

  .selection-button {
    right: 10px;
    width: 200px;
    height: 48px;
    box-shadow: 0 5px 12px get_color('primary', 0, 0.6);
    z-index: 2;
  }

  .p-datepicker {
    padding-top: 10px;
    display: block;
    position: relative;
    $cell-size: 24px;

    @mixin range-cell-background() {
      content: '';
      position: absolute;
      display: block;
      width: $cell-size;
      height: $cell-size;
      background-color: get_color('primary', 30);
      opacity: 0.08;
    }
    > a,
    > span {
      padding: 0;
    }

    .p-cell-wrapper {
      height: 46px;
      display: flex;
      flex-direction: column;
      align-items: center;
      opacity: 0.99;
      cursor: pointer;

      .p-cell {
        height: $cell-size;
        text-align: center;
        box-sizing: border-box;
        width: 100%;
        line-height: 14px;
        padding-top: 10px;

        &.p-with-count {
          padding-top: 0;
        }

        &.p-today {
          color: get_color('secondary', 50) !important;
        }
        &.p-highlight {
          color: get_color('neutral', 100);
          &::after {
            content: '';
            position: absolute;
            width: $cell-size;
            height: $cell-size;
            border-radius: 50%;
            background-color: get_color('primary', 50);
            z-index: -1;
          }
        }
        &.p-range {
          &-start::before {
            @include range-cell-background;
            right: 0;
            width: 50%;
          }
          &-end::before {
            @include range-cell-background;
            left: 0;
            width: 50%;
          }

          &-between::after {
            @include range-cell-background;
            width: inherit;
          }
        }
      }

      .cell-count {
        height: 12px;
        margin-top: 2px;
        line-height: 12px;
        color: get_color('primary', 0, 0.4);
      }

      &.p-disabled-date {
        opacity: 0.6;
        cursor: not-allowed;
      }

      &.p-non-working-date {
        background-color: get_color('neutral', 96);
      }
    }
  }
}
