@import '~/client/src/shared/theme.module';

.rich-text-content {
  .bp3-icon.bp3-icon-chevron-left svg {
    width: 30px;
    height: 30px;

    path {
      fill: get_color('neutral', 60);
    }
  }

  .rich-text-editor {
    width: 100%;
    .ql-container {
      border: none;
      font-size: 16px;
      min-height: 300px;

      .ql-editor {
        width: 100%;
        min-height: 400px;
        cursor: text;
        padding: 0;
        margin: 12px 6px;
      }
    }
  }
}
