@import '~/client/src/shared/theme.module';

.globe-item {
  min-width: 27px;
  min-height: 27px;
  &-text {
    top: 0;
    min-width: max-content;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
      1px 1px 0 #fff;
    &.hightlighted {
      border: 1px solid get_color('primary', 60);
    }
  }
}
