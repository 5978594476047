@import '~/client/src/shared/theme.module';

.compact-actions-menu-modal {
  $row-height: 50px;

  z-index: 100;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 100%;
  max-height: calc(100% - $row-height) !important;

  .content {
    border-radius: 10px 10px 0 0;
  }
  .common-row {
    min-height: $row-height;
    border-bottom: 1px solid get_color('slate', 90);
    padding: 0 10px;
  }

  .footer {
    min-height: $row-height;
    max-height: $row-height;
  }
}
