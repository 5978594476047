@import '~/client/src/shared/theme.module';

.thread {
  &-line-centred {
    margin-left: 8px;
  }

  &-without-padding {
    padding-bottom: 0 !important;
  }

  &-vertical-line {
    width: 50%;
    height: 100%;
    border-right: 1px solid;

    &.thread-vertical-line-semi-height {
      height: 50%;
    }
  }

  &-horizontal-line {
    &-container {
      height: 28px;
    }

    &-cross {
      position: absolute;
      width: 147%;
      margin: 0 0 0 -8px;
      height: 50%;
      border-bottom: 1px solid;
    }

    width: 100%;
    height: 50%;
    border-bottom: 1px solid;
  }

  &-first-line {
    height: 22px;
  }
}

.thread-vertical-line,
.thread-horizontal-line,
.thread-horizontal-line-cross,
.circle {
  border-color: get_color('neutral', 70);
}

.rfi-thread {
  .thread-vertical-line,
  .thread-horizontal-line,
  .thread-horizontal-line-cross,
  .circle {
    border-color: get_color('error', 50);
  }
}

.schedule-comment-thread {
  .thread-vertical-line,
  .thread-horizontal-line,
  .thread-horizontal-line-cross,
  .circle {
    border-color: get_color('error', 50);
  }
}

.category-of-variance-thread,
.safety-hazard-thread {
  .thread-vertical-line,
  .thread-horizontal-line,
  .thread-horizontal-line-cross,
  .circle {
    border-color: get_color('primary', 30);
  }
}

.photo-thread {
  .thread-vertical-line,
  .thread-horizontal-line,
  .thread-horizontal-line-cross,
  .circle {
    border-color: get_color('primary', 30);
  }
}

.flag-thread {
  .thread-vertical-line,
  .thread-horizontal-line,
  .thread-horizontal-line-cross,
  .circle {
    border-color: get_color('error', 50);
  }
}

.invisible-thread {
  .thread-vertical-line,
  .thread-horizontal-line,
  .thread-horizontal-line-cross,
  .circle {
    border-color: transparent;
  }
}
