@import '~/client/src/shared/theme.module';

.info-container {
  max-width: 600px;

  .logo-container svg {
    height: 22px;
    width: 22px;
  }

  .contact-help a {
    font-size: 18px;
  }
}
