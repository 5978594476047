@import '~/client/src/shared/theme.module';

.languages {
  z-index: 999;
  position: absolute;
  top: 50px;
  left: -10px;
  width: 120px;
  background-color: get_color('neutral', 100);

  &-item {
    line-height: normal;
    padding: 10px;
    &:hover {
      color: get_color('primary', 30);
      font-weight: 600;
    }
  }
}
.language-icon {
  height: 20px;
}
