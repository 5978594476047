@import '~/client/src/shared/theme.module';

.moved-to-current-section-tooltip {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: get_color('primary', 20);
  border-radius: 15px 15px 0 0;
  width: 100%;
  transition: bottom 1s;
  -webkit-transition: bottom 1s;
  -moz-transition: bottom 1s;
  -o-transition: bottom 1s;

  &.hidden {
    bottom: -300px;
  }
}
