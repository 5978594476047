.sidebar-column {
  &.icon {
    flex-basis: 55px;
    padding-left: 18px;
  }

  &.title {
    margin-top: 1px;
    text-transform: capitalize;
  }
}
