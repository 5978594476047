@import '~/client/src/shared/theme.module';

.permit-form-fields {
  position: relative;

  .dark-primary-icon {
    svg g path {
      &:nth-child(1) {
        fill: get_color('primary', 70);
        stroke: get_color('primary', 70);
      }
      &:nth-child(2) {
        fill: get_color('primary', 70);
      }
    }
  }

  .permit-log-container {
    .file-attachment {
      margin: 5px 0 !important;
      .file-name {
        max-width: 240px !important;
      }
    }
    .user-profile-preview-additional-info {
      max-width: 235px !important;
    }
    .invisible-thread {
      padding: 0 !important;
    }
  }

  .fields-section {
    > .row {
      max-width: 100%;
      min-width: 100%;
    }

    .inspection-frequency-field {
      width: 55px;
    }
    .inspection-frequency-type-field {
      width: 82px;
    }

    .permit-field-table {
      &.view-mode {
        max-height: 50vh;
        pointer-events: all !important;
        word-break: break-word;

        table {
          border-collapse: separate;

          thead {
            top: 0;
            z-index: 1;
          }

          th:first-child,
          td:first-child {
            border-left: 1px solid get_color('grey', 84);
          }

          th.field-header,
          td.field-value-cell {
            min-width: 150px;
            max-width: 300px;

            .user-profile-preview {
              word-break: normal !important;
            }
          }
        }
      }

      .table-row-containter {
        max-height: 50vh;

        .table-row-header {
          top: 0;
          z-index: 1;
        }
      }
    }
  }

  &.col-2 .fields-section {
    > .row {
      max-width: 50%;
      min-width: 50%;
    }
  }

  .measure-field {
    width: 50%;
    margin-top: 0 !important;
  }

  .multi-select-field {
    .multi-select-content {
      max-height: 300px;
    }
  }

  .permit-field-icon:not(.bg-secondary-80) {
    background: get_color('neutral', 92);
    svg {
      color: get_color('neutral', 50);
      path,
      polygon {
        fill: get_color('neutral', 50);
      }
    }

    &.unfilled svg path {
      fill: none;
      stroke: get_color('neutral', 50);
    }
  }

  .permit-type-icon svg {
    width: 20px;
    height: 20px;
  }

  &-upload {
    border: none;

    .icon-wrapper {
      width: 40px;
      min-height: 40px;
    }

    & button {
      padding: 0;
      width: unset;
      height: unset;

      i {
        font-style: normal;
        font-weight: 600;
      }
    }
  }

  .inspection-icon {
    &.dark {
      svg path {
        fill: get_color('neutral', 0) !important;
      }
    }
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: get_color('neutral', 100);
        fill-opacity: 1;
      }
    }
  }
  .row.iconless-row {
    margin-left: 44px;
    margin-right: 12px;
    max-width: calc(100% - 56px);
    min-width: calc(100% - 56px);
  }

  &.col-2 .row.iconless-row {
    max-width: calc(50% - 56px);
    min-width: calc(50% - 56px);
  }

  .preview-img {
    max-height: 100px;
    max-width: 100%;
    object-fit: contain;
    object-position: left;
  }

  .section-separator {
    .left-line {
      min-width: 44px;
    }
  }

  .site-permit-checkbox {
    height: 48px;
    &.checked {
      color: get_color('primary', 50);
    }
  }

  .bp3-icon.bp3-icon-chevron-left svg {
    width: 30px;
    height: 30px;

    path {
      fill: get_color('neutral', 60);
    }
  }

  .vertical-object-levels-selector .hierarchy-delivery-attribute-tag-content {
    overflow: hidden;
  }

  .building-levels-selector {
    position: absolute;
    width: 260px;
    min-height: 300px;
    max-height: 100% !important;
    background-color: get_color('neutral', 100);
    border-radius: 4px;
    left: 60px;
    bottom: 0;
    box-shadow: 0 10px 30px 0 get_color('neutral', 50);
    z-index: 10;
  }

  .questionnaire-items button {
    min-width: 55px;
  }

  .palette-blue {
    color: get_color('primary', 40);
  }

  .subsection-field {
    background: get_color('neutral', 92) !important;
  }

  .permit-form-attachment {
    margin-left: 0 !important;
    margin-right: 0 !important;
    > div > div {
      overflow: hidden;
    }
    .file-name {
      display: block !important;
    }
  }
}

.site-permit-creation-form .activity-log-entry-left-col {
  min-width: 90px;
}

.bp3-icon.bp3-icon-arrow-left {
  z-index: 1;
}

.preview-image-container {
  div,
  div img {
    max-height: 100%;
    max-width: 100%;
    text-align: center;
  }
}
.checklist-dnd-area {
  .upload-schedule-dnd-container {
    .upload-schedule-block-bordered {
      width: 90% !important;
      position: relative;
      background-color: get_color('neutral', 96);
      height: 64px !important;
      font-size: 14px !important;
    }
  }
}

.site-permit-creation-form-content .action-bar {
  z-index: 8;
}

.site-permit-creation-form-content {
  .permit-location-picker,
  .permit-date-picker,
  .permit-user-picker,
  .permit-company-picker {
    z-index: 10;
  }

  .user-profile-preview-additional-info {
    display: flex;
    flex-direction: row;
    overflow: hidden !important;
  }

  .compact-popup-modal {
    .icon-wrapper {
      position: relative !important;
      margin-left: 10px !important;
      left: 0 !important;
    }
    .title-container {
      margin-left: 5px !important;
      margin-right: 35px !important;
    }
  }
}

.form-details-tabs {
  .tab-text {
    color: get_color('neutral', 0, 0.75);
  }

  .active {
    .tab-text {
      color: get_color('primary', 40);
      border-bottom: 2px solid get_color('primary', 40);
    }
  }
}
