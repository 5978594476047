@import '~/client/src/shared/theme.module';

.select-time-modal {
  display: none;
  bottom: 0;
  opacity: 0;
  transition: all 400ms;

  &.shown {
    display: block;
    opacity: 1;
  }
}
