@import '~/client/src/shared/theme.module';

.swipable-date-picker {
  .week-rows-container {
    white-space: nowrap;
    user-select: none;

    &.animation {
      transition: transform 0.5s ease;
    }
  }

  .week-days-row {
    display: inline-flex;
    .week-day {
      width: 32px;
      font-size: 17px;
      line-height: 32px;
      height: 32px;
      text-align: center;
      border-radius: 16px;

      &.today {
        color: get_color('secondary', 40);

        &.selected {
          background-color: get_color('secondary', 40);
          color: get_color('neutral', 0);
        }
      }

      &.selected {
        background-color: get_color('grey', 88);
        color: get_color('neutral', 100);
      }
    }
  }
}
