@import '~/client/src/shared/theme.module';

$size: 16px;

.filter-option-checkbox-container {
  &.p-checkbox {
    margin: 0;
  }

  .p-checkbox-box {
    min-height: $size;
    max-height: $size;
    height: $size;
    min-width: $size;
    max-width: $size;
    width: $size;
    line-height: $size;

    margin: 0;

    border: 1px solid get_color('primary', 30);
    color: get_color('primary', 30);

    .pi-check:before {
      content: '\2714';
    }

    .pi-minus:before {
      content: '\2212';
    }
  }

  &.p-radio {
    .p-checkbox-box {
      border-radius: 50%;

      .pi-check:before {
        content: '';
        display: block;
        height: 14px;
        width: 14px;
        background-image: radial-gradient(
          get_color('primary', 30),
          get_color('primary', 30) 40%,
          transparent 40%
        );
        margin-left: 1px;
      }
    }
  }
  &.reverted-colors {
    .p-checkbox-checked {
      .p-checkbox-box {
        color: get_color('neutral', 100) !important;
        background-color: get_color('primary', 50) !important;
      }
    }
  }
}
