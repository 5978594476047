@import '~/client/src/shared/theme.module';

$view-mode-option-size: 40px;
$border-radius-5: 5px;
$header-button-height: 36px;

.logistics {
  overscroll-behavior: none;
  .notification-item {
    border-radius: 4px;
    border: 1px solid get_color('neutral', 80);
    width: 40px;
    min-width: 40px;
    height: 40px;
    margin-inline: 4px;
    i {
      display: inline-flex;

      svg {
        width: 18px;
        height: 18px;

        path {
          fill: get_color('neutral', 10);
        }
      }
    }
  }

  .logistics-footer {
    bottom: 0;
  }

  .body-header {
    top: 0;
  }

  .draggable-bar {
    top: calc(90% - 80px);
  }

  .sitemaps {
    height: 100%;
  }

  .notifications-badge {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 15px;
  }

  .view-mode {
    min-width: $view-mode-option-size;
    max-width: $view-mode-option-size;
    min-height: $view-mode-option-size;
    max-height: $view-mode-option-size;
    &.selected {
      background-color: get_color('primary', 96);
      i svg path {
        fill: get_color('violet', 40);
      }
    }
  }

  &-header-button {
    .filter-icon svg path {
      stroke: get_color('neutral', 0);
      fill: get_color('neutral', 0);
    }
  }

  .menu-icon {
    svg path {
      stroke: get_color('neutral', 100);
      stroke-width: 0.7px;
    }
  }

  &-header {
    height: 54px !important;
    &.logistics-transparent-header {
      background-color: transparent;
      position: absolute;
      top: 10px;
      left: 16px;
      right: 16px;
      z-index: 100;
      border-radius: 4px;
      padding: 6px 8px 6px 12px;
      height: 52px !important;
      background-color: get_color('neutral', 100);
    }
  }

  .transparent-header {
    background-color: transparent;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
  }

  .site-content {
    max-height: 86px;
    overflow: hidden;

    p {
      margin: 0;
    }

    img,
    br {
      display: none;
    }
  }

  .list-number {
    min-width: 34px;
  }

  .items-list {
    height: calc(100% - 35px);
  }

  .delivery-icon,
  .forms-icon,
  .announcement-icon {
    max-height: 20px;

    svg {
      min-width: 24px;
      min-height: 24px;
    }
  }

  &-weather-label-container {
    height: 39px;

    .weather-label > div {
      padding: 0 8px;
    }
  }
  .map-filters {
    top: 70px;
  }
}

.displayed-announcement-content {
  .bp3-icon.bp3-icon-chevron-left svg {
    width: 30px;
    height: 30px;

    path {
      fill: get_color('neutral', 60);
    }
  }
}
