@import '~/client/src/shared/theme.module';

.sidebar-badge {
  background-color: get_color('error', 30);
  margin-top: 1px;
  border: 1px solid get_color('neutral', 100);
  border-radius: 10px;
  min-width: 14px;
  max-height: 14px;
  padding: 8px 6px;
  font-weight: 400;
}
