@import '~/client/src/shared/theme.module';

.resizable-box {
  z-index: 3;
}

.top-dot {
  top: 0;
  right: 20px;
  position: absolute;
  z-index: 10;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  background-color: get_color('neutral', 100);
  border: 1px solid get_color('neutral', 0);
}

.bot-dot {
  top: -10px !important;
  left: 20px !important;
  position: absolute !important;
  border-radius: 10px;
  width: 10px !important;
  height: 10px;
  background-color: get_color('neutral', 100);
  border: 1px solid get_color('neutral', 0);
  z-index: 10;
}

.unset-draggable {
  left: unset !important;
}

.bp3-tooltip-calendar-event .permit-card {
  max-width: 350px;
}
