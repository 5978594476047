@import '~/client/src/shared/theme.module';

.workflow-card {
  .main-icon {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .secondary-icon {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .title-text {
    max-width: 250px;
  }

  .team-row {
    svg {
      path {
        fill: get_color('neutral', 0);
      }
    }
  }

  p {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
  }

  .action-menu {
    box-shadow: 0px 1px 7px 0px get_color('neutral', 0);
    width: 120px;
    right: 30px;
    z-index: 10;
    .option {
      height: 30px;
      svg g path,
      svg g polygon {
        fill: get_color('primary', 50);
      }
    }
  }
}
