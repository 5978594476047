@import '~/client/src/shared/theme.module';
@import '~/client/src/shared/styles';

.file-attachment {
  @extend .ba-light-input-border;

  .file-name {
    max-width: 265px;
  }

  &.pdf-preview {
    width: 370px;
    height: 210px;
  }

  &.small-pdf-preview {
    width: 320px;
    height: 210px;
  }

  &:hover {
    @extend .beautiful-shadow;
  }
  .preview-img {
    max-height: 100px;
    max-width: 100%;
    object-fit: contain;
    object-position: left;
  }

  .bp3-icon.bp3-icon-cross {
    &:hover {
      @extend .ba-light-input-border;
      @extend .beautiful-shadow;
    }
  }
}
