$count-width: 50px;

.company-profile-preview {
  .compact-preview-menu {
    bottom: 50px;
    left: 15px;
    right: 15px;
    z-index: 10;
  }

  .hierarchy-delivery-attribute-tag {
    display: flex;
  }

  .count-container {
    max-width: $count-width;
    text-align: center;
  }

  .tags-cell-container {
    .tag-text {
      max-width: unset !important;
    }

    &.plural .tag-cell {
      max-width: calc(50% - $count-width / 2);
    }

    &.singular .tag-cell {
      max-width: 100%;
    }
  }
}
