@import '~/client/src/shared/theme.module';

.multi-day-events-container {
  width: 100%;
  background-color: get_color('neutral', 100);
  z-index: 9;
  left: 0;
  overflow: auto;
  padding: 5px 10px 5px;
  max-height: 30dvh;

  .x-axis-column-width {
    min-width: 30px;
  }

  .multi-day-event {
    height: 24px;
    margin-bottom: 1px;
    display: flex;
    align-items: center;

    &.extends-left {
      border-left-style: dotted !important;
      border-left-width: 2px !important;
    }
    &.extends-right {
      border-right-style: dotted !important;
      border-right-width: 2px !important;
    }
  }

  .collapse-container {
    display: flex;
    height: 22px;
    align-items: center;
    padding: 6px 0;
  }
}
