@import '~/client/src/shared/theme.module';

.action-bar {
  * {
    outline: none;
  }
  padding: 10px;
  background-color: get_color('neutral', 100);
  border-top: solid 2px get_color('grey', 88);

  &-flag-btn .flag-badge {
    fill: get_color('neutral', 70);
  }

  &-flag-btn.active rect {
    fill: get_color('error', 50) !important;
  }

  &-rfi-btn.active rect {
    fill: get_color('secondary', 50);
  }

  &-image-btn.active g {
    stroke: get_color('primary', 40);
  }

  &-input {
    margin-bottom: 10px;
    padding-bottom: 0px;
    border: 0px solid get_color('neutral', 100);
  }

  &-btns {
    display: flex;
    flex-direction: row;
  }

  &-btns > button:not(:last-child) {
    margin-right: 20px;
    color: get_color('neutral', 100);
    border: none;
    background: none;
    padding: 0;
  }

  &-send-btn {
    margin-left: auto;
    padding: 6px 10px;
    border-radius: 4px;
    border: 0px;
    background-color: inherit;
    color: get_color('primary', 30);
    font-size: 16px;
  }
}
