@import '~/client/src/shared/theme.module';
@import '~/client/src/shared/mixins';

.date-picker-calendar {
  position: absolute;
  top: 100%;
  height: 0;
  background-color: get_color('neutral', 100);
  z-index: 101;
  width: 100%;
  left: 0;
  transition: top 300ms;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  .has-delivery {
    &::after {
      @include big-dot(get_color('error', 50));
      left: unset !important;
      right: 5px !important;
      bottom: unset !important;
    }
  }

  &.shown {
    $top: 30px;
    top: $top;
    // When using vh we get the wrong size on mobile devices. For such cases it is better to use dynamic vh(dvh)
    height: calc(100dvh - $top);
    transition: height ease-in $DEFAULT_BANNER_DISMISS_ANIMATION_TIME;

    .with-banner & {
      height: calc(100dvh - $top - $DEFAULT_BANNER_HEIGHT);
    }
  }

  .calendar-cross-icon {
    svg {
      color: get_color('primary', 50);
    }
  }

  .month-title {
    position: absolute;
    top: 50px;
    left: 125px;
  }

  .apply-button {
    height: 48px;
    border-radius: 4px;
    background-color: get_color('primary', 50);
  }
}
