@import '~/client/src/shared/theme.module';

$scroll-resizer-width: 8px;

.group-by-menu {
  z-index: 4;
  position: absolute;
  width: 360px;
  height: 332px;
  top: 35px;
  left: 50px;
  border-radius: 8px;
  background-color: get_color('neutral', 100);
  .bp3-control {
    margin-bottom: 0 !important;
    padding-right: 15px !important;
  }
  .attribute-type-select {
    z-index: 20;
    position: absolute;
    width: 240px;
    top: 20px;
    left: 50px;
    border-radius: 8px;
    background-color: get_color('neutral', 100);
    box-shadow: 0px 8px 10px get_color('neutral', 0, 0.14),
      0px 3px 14px get_color('neutral', 0, 0.12),
      0px 5px 5px get_color('neutral', 0, 0.2);
  }

  .attributes-list {
    height: calc(100% - 70px);

    .sortable-list-holder {
      &::-webkit-scrollbar {
        width: $scroll-resizer-width;
        height: $scroll-resizer-width;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: get_color('primary', 30);
      }
    }
  }
}
