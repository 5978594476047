$animation-duration: 1s;

.logo-spinner {
  -webkit-animation: spin $animation-duration linear infinite;
  -moz-animation: spin $animation-duration linear infinite;
  animation: spin $animation-duration linear infinite;
  display: inline-block;

  svg {
    width: 100%;
    height: 100%;
  }

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
