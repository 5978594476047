@import '~/client/src/shared/theme.module';

$scrollable-button-width: 40px;
$view-mode-option-size: 40px;
$border-radius-10: 10px;
$header-button-height: 36px;

.draggable-bar {
  z-index: 10;

  .draggable-section {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  .delivery-card {
    height: fit-content !important;
  }

  left: 0;
  z-index: 11;
  border-top-left-radius: $border-radius-10;
  border-top-right-radius: $border-radius-10;

  .list-header {
    border-top-left-radius: $border-radius-10;
    border-top-right-radius: $border-radius-10;
    .header-button {
      min-height: $header-button-height;
      max-height: $header-button-height;
      .delivery-icon svg path {
        stroke: get_color('neutral', 0);
        fill: get_color('neutral', 0);
      }
      .common-icon svg g path {
        fill: get_color('neutral', 0);
      }
      &.selected {
        .delivery-icon svg path {
          stroke: get_color('primary', 30);
          fill: get_color('primary', 30);
        }
        .common-icon svg g path {
          fill: get_color('primary', 30);
        }
      }
    }
  }
  .items-list {
    height: calc(100% - 54px);
  }

  .drag-handle {
    flex-grow: 0 !important;
    &.not-dragging {
      top: 0 !important;
      left: 0 !important;
      transform: translate(0px, 0px) !important;
    }
  }

  .transparent-header {
    background-color: transparent;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
  }

  .scrollable-button {
    min-width: $scrollable-button-width;
    max-width: $scrollable-button-width;
    min-height: 4px;
  }
}
