@import '~/client/src/shared/theme.module';

.landscape-container {
  position: relative;
  background-color: get_color('neutral', 80);
  width: 100%;
  height: 100%;
}

.align-landscape {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
}
