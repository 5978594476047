@import '~/client/src/shared/theme.module';

.form-action-bar {
  * {
    outline: none;
  }
  .title-text {
    line-height: 16px;
    font-weight: 600;
  }
  .image-preview-holder {
    overflow: visible;
    max-width: 64px;
    min-height: 64px;
    margin-top: 20px;
  }
  .remove-icon {
    svg path {
      fill: get_color('neutral', 100);
    }
  }
  .attachment-upload-button {
    width: 24px;
    height: 24px;
  }
}

.site-permit-creation-form-content {
  .items-list {
    height: calc(100% - 46px) !important;
  }
}

.delivery-details-footer {
  .items-list {
    height: calc(100% - 40px) !important;
  }
}

.fixed-size-menu {
  .items-list {
    height: 100% !important;
  }
}

.action-bar-form-holder {
  height: calc(100% - 58px);
}

.slack-bar-send-btn {
  padding: 10px !important;
  background-color: get_color('primary', 50) !important;
}

.slack-bar-send-btn-fine {
  padding: 10px !important;
  border: none;
  background-color: unset;
  svg path {
    stroke: get_color('neutral', 50) !important;
  }
}
