@import '~/client/src/shared/theme.module';

.photo-gallery-component {
  height: calc(100% - 36px);
  width: 100%;
  padding: 0 20px;
  overflow: scroll;

  .photo-gallery-date {
    margin: 0;
    font-size: 12px;

    &.today {
      font-weight: 600;
      color: get_color('secondary', 40);
    }
  }

  .photo-gallery-photos {
    display: flex;
    flex-wrap: wrap;

    .photo-container {
      margin: 1px 7px 1px 0;
    }
  }

  .section-title {
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: 20px;
    font-weight: 600;
  }
}
