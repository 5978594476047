@import '~/client/src/shared/theme.module';

.monthpicker-container {
  display: none;
  position: absolute;
  width: 200px;
  z-index: 10;
  background-color: get_color('neutral', 100);

  &.shown {
    display: block;
  }

  .monthpicker-header {
    display: block;
    padding: 0.5rem 0;
    position: relative;
    &-nav {
      &-prev {
        cursor: pointer;
        height: 1.8em;
        position: absolute;
        top: 0.5em;
        width: 1.8em;
        svg {
          height: inherit;
          path {
            fill: get_color('primary', 30);
            fill-opacity: 1;
          }
        }
        left: 0.125em;
      }
      &-next {
        cursor: pointer;
        height: 1.8em;
        position: absolute;
        top: 0.5em;
        width: 1.8em;
        svg {
          height: inherit;
          path {
            fill: get_color('primary', 30);
            fill-opacity: 1;
          }
        }
        right: 0.125em;
      }
    }
    &-title {
      line-height: 1.8rem;
      margin: 0 2.3rem;
      text-align: center;
    }
  }

  .monthpicker-table {
    display: flex;
    flex-wrap: wrap;
    &-cell {
      flex-grow: 1;
      width: 33.3%;
      text-align: center;
      padding: 0.5em;
      cursor: pointer;

      &:hover {
        color: get_color('neutral', 100);
        background-color: get_color('primary', 50);
      }
    }
  }
}
