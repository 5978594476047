@import '~/client/src/shared/theme.module';

$tab-icon-size: 16px;

.login-view-container {
  .tabs-container {
    svg {
      height: $tab-icon-size;
      width: $tab-icon-size;

      * {
        fill: get_color('neutral', 50);
      }
    }

    .active {
      border-radius: 4px;
      background-color: get_color('primary', 40, 0.12);

      * {
        color: get_color('primary', 40);
        fill: get_color('primary', 40);
      }
    }
  }

  .compact-popup-modal.shown {
    height: 100%;
    top: 0;
    border-radius: 0;
  }

  .otp-input-container {
    .vi__container--default {
      height: 36px;
    }

    .vi__character--selected--default {
      outline: 2px solid get_color('primary', 40);
      color: inherit;
    }

    .vi__character--default {
      margin-left: 16px;
      line-height: 36px;
      font-size: 22px;
      background-color: get_color('neutral', 96);
      border: none;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .languages {
    top: 30px;
    right: 0;
    left: auto;
  }
}
