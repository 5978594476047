@import '~/client/src/shared/theme.module';

.compact-dialog {
  display: none;
  position: absolute;
  background-color: get_color('neutral', 100);
  z-index: 100;
  width: 320px;
  top: 50px;
  left: calc(50% - 160px);
  padding: 0 20px;

  opacity: 0;
  transition: all 400ms;

  &.shown {
    display: block;
    opacity: 1;
  }
}
