@import '~/client/src/shared/theme.module';

.permit-field-icon {
  svg {
    width: 18px;
    height: 18px;
    color: get_color('neutral', 0);

    path,
    polygon {
      fill: get_color('neutral', 0);
    }
  }

  &.unfilled svg path {
    fill: none;
    stroke: get_color('neutral', 0);
  }
}
