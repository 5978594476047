@import '~/client/src/shared/theme.module';

$loading-spinner-size: 20px;
@mixin loading-screen($icon-size: 84px) {
  &:before {
    content: '';
    opacity: 0.6;
    background-color: get_color('neutral', 100);
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: $icon-size;
    height: $icon-size;
    z-index: 100;
    border-radius: 10px;
  }

  &:after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    width: $loading-spinner-size;
    height: $loading-spinner-size;
    border-radius: 50%;
    border: 2px solid get_color('neutral', 70);
    border-top-color: get_color('neutral', 50);
    animation: spinner 0.6s linear infinite;
  }
}

.layers-container {
  cursor: default;
  z-index: 5;
  bottom: 20px;
  left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.compact-view {
    position: absolute;
    z-index: 10;
    left: unset;
    right: 55px;
  }

  .layers-container-holder {
    &:active {
      @include loading-screen;
    }
  }
  &.above-show-on {
    bottom: 50px !important;
  }

  .secondary-cell {
    &.active {
      svg {
        border: 3px solid get_color('violet', 40);
        border-radius: 10px;
      }

      .mode-text {
        color: get_color('violet', 40);
      }
    }
  }
  .secondary-cell-icon {
    &:active {
      @include loading-screen(48px);
    }
  }

  .selector-app-toggle {
    svg path {
      fill: get_color('neutral', 50);
    }
  }
  .more-layer-small-icon {
    height: 16px;
  }

  &.satellite {
    .layers-container-holder {
      background-color: get_color('neutral', 100);
    }

    .main-cell {
      background-color: get_color('neutral', 100);

      &:hover {
        .main-icon {
          svg {
            border: 2px solid get_color('neutral', 100);
          }
        }
      }
    }
    .main-icon {
      rect {
        stroke: get_color('neutral', 100);
      }
    }
    .more-layer-small-icon {
      svg path {
        fill: get_color('neutral', 100);
      }
    }
    .layers-text {
      color: get_color('neutral', 100);
    }
    .type-text {
      color: get_color('neutral', 100);
    }
  }

  &.street {
    .layers-container-holder {
      background-color: get_color('neutral', 0);
    }

    .main-cell {
      background-color: get_color('neutral', 0);

      &:hover {
        .main-icon {
          svg {
            border: 2px solid get_color('neutral', 0);
          }
        }
      }
    }
    .more-layer-small-icon {
      svg path {
        fill: get_color('neutral', 0);
      }
    }
    .main-icon {
      rect {
        stroke: get_color('neutral', 0);
      }
    }
    .layers-text {
      color: get_color('neutral', 0);
    }
    .type-text {
      color: get_color('neutral', 0);
    }
  }

  .hidden-layer {
    display: none;
  }

  .main-cell {
    border-radius: 10px;
    height: 84px;

    .layers-text {
      bottom: 0;
    }
    .type-text {
      visibility: hidden;
      bottom: 0;
    }
    &:hover {
      .type-text {
        visibility: visible;
      }
      .layers-text {
        visibility: hidden;
      }
      .main-icon {
        svg {
          border-radius: 10px;
        }
      }
    }
    .main-icon {
      padding: 2px;
      transition: 1s ease-out all;
    }
    .main-text {
      position: absolute;
      bottom: 5px;
    }
  }

  &:hover {
    .hidden-layer {
      display: inherit;
    }
  }

  .extended-view {
    box-shadow: 0px 20px 50px -20px get_color('neutral', 50);

    .map-type-icon {
      position: relative;
      svg {
        width: 48px;
        height: 48px;
        border-radius: 10px;
        border: 3px solid get_color('violet', 100);
      }
      &.active {
        svg {
          border: 3px solid get_color('violet', 40);
        }
        .mode-text {
          color: get_color('violet', 40);
        }
      }
      &:active {
        @include loading-screen(48px);
      }
    }

    &.compact-view {
      z-index: 9;
      position: absolute;
      right: 10px;
      top: 170px;
      box-shadow: 0px 2px 2px get_color('neutral', 0, 0.25),
        0px 3px 1px get_color('neutral', 0, 0.25),
        0px 1px 5px get_color('neutral', 0, 0.25);
    }
  }
}
